//mascara a pedido do Aurelio -> formata numeros de forma geral
/*
 * @param value = valor
 * @param decimals = número de casas decimais
 * @param thousandsSep = separador para valores maiores de 1.000
 * @param decPoint = separador casas decimais
 */
export const number_format = (value, decimals = 0, thousandsSep = '.', decPoint = ',') => {
    const valueEntrada = String(value);
    if (valueEntrada.length === 0) {
      return value;
    } else {
      let simboloNegativo = false;
      if (valueEntrada[0] === '-') {
        simboloNegativo = true;
      } else {
        simboloNegativo = false;
      }
      if (decimals === 0) {
        const valor = valueEntrada?.replace(/\D/g, "");
        return  'R$ ' + simboloNegativo ? '-' + valor.replace(/\B(?=(?:\d{3})+(?!\d))/g, thousandsSep) : 'R$ ' +  valor.replace(/\B(?=(?:\d{3})+(?!\d))/g, thousandsSep);
      } else {
        const valor = valueEntrada?.replace(/\D/g, "");
        const modificarValor = valor?.slice(0, -Math.abs(decimals)) + "." + valor?.slice(-Math.abs(decimals));
        let inteiro = modificarValor.split('.')[0];
        let decimal = modificarValor.split('.')[1];
        return simboloNegativo ? 'R$  -' + inteiro.replace(/\B(?=(?:\d{3})+(?!\d))/g, thousandsSep) + decPoint + decimal : 'R$ ' +  inteiro.replace(/\B(?=(?:\d{3})+(?!\d))/g, thousandsSep) + decPoint + decimal
      }
    }
  }
  
  /***
   * unMask para number_format
   * @value = valor a ser retirada a máscara
   * @typeSeparator = tipo separador das casas decimais. Caso esteja usando casts, typeSeparator = ,
   * 
   */
  export const unMask_number_format = (value, typeSeparator = '.') => {
    const valueEntrada = String(value);
    if (valueEntrada.length === 0) {
      return valueEntrada;
    } else {
      let simboloNegativo = false;
      let isDecimal = true;
      if (valueEntrada[0] === '-') {
        simboloNegativo = true;
      } else {
        simboloNegativo = false;
      }
      const modificarValor = valueEntrada?.split(',')
      const modificarValorInteiro = +modificarValor[0]?.replace(/\D/g, "");
      const modificarValorDecimal = modificarValor[1];
  
      return (
        simboloNegativo ? ('-' + modificarValorInteiro + `${isDecimal ? `${typeSeparator}${modificarValorDecimal}` : ''}`) :
          (modificarValorInteiro + `${isDecimal ? `${typeSeparator}${modificarValorDecimal}` : ''}`)
      )
    }
  }
  
  export const mask_format_number_static = (number, decimals = 0, thousandsSep = '.', decPoint = ',') => {
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '')
    const n = !isFinite(+number) ? 0 : +number
    const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
    const sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep
    const dec = (typeof decPoint === 'undefined') ? '.' : decPoint
    let s = []
    const toFixedFix = function (n, prec) {
      if (('' + n).indexOf('e') === -1) {
        return +(Math.round(Number(n + 'e+' + prec)) + 'e-' + prec)
      } else {
        const arr = ('' + n).split('e')
        let sig = ''
        if (+arr[1] + prec > 0) {
          sig = '+'
        }
        return (+(Math.round(Number(+arr[0] + 'e' + sig + (+arr[1] + prec))) + 'e-' + prec)).toFixed(prec)
      }
    }
  
    s = (prec ? toFixedFix(n, prec).toString() : '' + Math.round(n)).split('.')
    if (s[0].length > 3) {
      s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
    }
  
    if ((s[1] || '').length < prec) {
      s[1] = s[1] || ''
      s[1] += new Array(prec - s[1].length + 1).join('0')
    }
    return 'R$ ' +  s.join(dec)
  }
  