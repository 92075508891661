import React, { Fragment } from 'react'
import { Bar, BarChart, Cell, Legend, Tooltip, XAxis, YAxis } from 'recharts'

const GraphBarChart = ({ titulo, dados, barLabel, multiBar, barSize, height, width }) => {
    const COLORS = ["#FF9D3B", "#24B3AF", "#71FFFA", "#0A6663"];

    return (
        <Fragment>
            <h3 style={{ color: '#24B3AF', fontWeight: 'bold', marginLeft: '3rem' }}>{titulo}</h3>
            <BarChart
                data={dados}
                height={height ?? 250}
                width={width ?? 500}>
                <XAxis
                    dataKey="datakey"
                    stroke="#b2b2b2" />
                <YAxis
                    stroke="#b2b2b2"
                />
                <Tooltip />
                {
                    multiBar ?
                        <Bar
                            barSize={barSize ?? 50}
                            dataKey={barLabel ?? "media"}
                        >
                            {dados.map((el, index) => (
                                <Cell fill={COLORS[index]} />
                            ))}
                        </Bar>
                        :
                        <Bar
                            barSize={barSize ?? 50}
                            dataKey={barLabel ?? "quantidade"}
                            fill={"#FF9D3B"}
                        />
                }

            </BarChart>
        </Fragment>
    )
}
export default GraphBarChart