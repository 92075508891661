import axios from 'axios'
import axiosRetry from 'axios-retry';
import { error as errorToast } from '../components/Basicos/funcoes';

const token = localStorage.getItem('token-orto');

export const axiosApi = axios.create({
    // baseURL: window.location.protocol + '//' + window.location.host + '/quizmed/api',
    // baseURL: 'http://127.0.0.1:8000/api',
    // baseURL: 'http://192.168.20.32:8000/api',
    // baseURL: 'http://54.242.212.192:8000/api',
    baseURL: 'https://api.quizmed.com.br/api',
    timeout: 240000,
    headers:
    {
        'Authorization': token ? `bearer ${token}` : '',
        'Content-Type': 'application/json'
    },
});

const errorInterceptor = axiosApi.interceptors.response.use(
    function (response) {
        return response;
    }, function (error) {
        console.log(error.response.status,error.response.data);
        errorToast(error.response.data.error);
        return Promise.reject(error);
    });

export const ativarRefreshToken = (setToken) => {
    const expires_in =  localStorage.getItem('expires-in-orto');
    setTimeout(async () => {
        const response = await axiosApi.post('/auth/refresh');
        const { access_token, expires_in } = response.data;
        localStorage.setItem('token-orto', access_token);
        localStorage.setItem('expires-in-orto', expires_in);
        axiosApi.defaults.headers.common['authorization'] = `bearer ${access_token}`;
        setToken(access_token);
    } , (expires_in - 90000));
}

axiosRetry(axiosApi, { retries: 3 });