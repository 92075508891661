import React, { useState, useEffect } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Form, Input } from 'reactstrap';
import { IoIosArrowBack, IoMdImages } from 'react-icons/io';
import { ImageDropzone } from '../../../components/Basicos'
import './styles.css'
import { axiosApi } from '../../../services/axiosInstances';
import { convertToSlug, delay, success } from '../../Basicos/funcoes';
import { SelectForm } from '../../Basicos';

const ModalNovo = (props) => {
  const [categoriaOptions, setCategoriaOptions] = useState();
  const [valorTitulo, setValorTitulo] = useState();
  const [valorSubtitulo, setValorSubtitulo] = useState();
  const [valorCategoria, setValorCategoria] = useState(0);
  const [img, setImg] = useState();

  const handleClick = async (event) => {
    const { name } = event.target;
    const { nome } = props;

    if (name === 'salvar') {
      //Preparar envio de dados:
      const formData = new FormData();

      if (img) {
        formData.append('icone', img, img.name);
        formData.append('img_dir', img.name);
      }
      formData.append('descricao', valorTitulo);

      switch (convertToSlug(nome)) {
        case 'subcategoria':
          formData.append('categoria_id', valorCategoria);
          break;
        case 'apoiadores':
          const cod = new Date();
          formData.append('user_id', 1);
          formData.append('nome', valorTitulo);
          formData.append('descricao', valorSubtitulo); //AKA site
          formData.append('codigo', cod.toString()); // AKA descricao ou data_patrocinio
          formData.append('status', true);
          break;
        default:
          break;
      }

      try {
        await axiosApi.post(convertToSlug(nome), formData)
        props.setOpenModal(false);
        success('Adicionado com Sucesso!');
        await delay(3000);
        window.location.pathname = props.caminho;
      } catch (error) {
        console.log(error)
      }
    }
    props.setOpenModal(false);
  };

  const onChange = (event) => {
    const { name, value } = event.target;

    if (name === 'titulo')
      setValorTitulo(value);
    else if (name === 'subtitulo')
      setValorSubtitulo(value);
    else if (name === 'img')
      setImg(value)
  };

  const selectOnchange = (nome, valor) => {
    setValorCategoria(valor);
  }

  const loadCategorias = async () => {
    const response = await axiosApi.get(`categorias?subcategoria=true`);
    setCategoriaOptions(response.data)

  };

  useEffect(() => {
    if (props.nome === 'Subcategorias') {
      loadCategorias();
    }
  }, []);

  return (
    <Modal isOpen={props.isOpen} backdrop={props.backdrop} setOpenModal={props.setOpenModal} size='lg'>
      <ModalHeader className="Modal-header">
        <Row noGutters>
          <Col>
            <button onClick={handleClick} className='Back-btn' name='cancelar'><IoIosArrowBack size="3rem" /></button>
          </Col>
          <Col md={8}>
            <h3 className='Modal-titulo'>{props.titulo}</h3>
          </Col>
          <Col>
            <button onClick={handleClick}
              className='Modal-btn align-content-middle'
              name='salvar'
              disabled={props.nome === 'Apoiadores' ?
                valorTitulo === undefined || valorSubtitulo === undefined
                : valorTitulo === undefined}>
              Salvar
            </button>
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        <Form>
          <Input
            type='text'
            className='Modal-input'
            name='titulo'
            placeholder={'Nome'}
            onChange={onChange}
            valid={valorTitulo !== undefined}
            invalid={valorTitulo === undefined || valorTitulo === '' || valorTitulo.length < 3}

          />

          {(props.subcategoria) ?
            <SelectForm
              name={"categorias"}
              options={categoriaOptions}
              onChange={selectOnchange}
              menuPortalTarget={document.body}
              placeholder={"Selecione a Categoria"}
              className={"Modal-input"} />
            :
            (props.apoiador) &&
            <Input
              type='text'
              className='Modal-input'
              name='subtitulo'
              placeholder={'Site'}
              onChange={onChange}
              valid={valorSubtitulo !== undefined}
              invalid={valorSubtitulo === undefined || valorSubtitulo === ''} />
          }

          <ImageDropzone setImg={setImg} />
        </Form>
      </ModalBody>
      <ModalFooter>
      </ModalFooter>
    </Modal>
  )
}

export default ModalNovo;