import React, { useState, useEffect } from 'react';
import { Col, InputGroup, Row, } from 'reactstrap';

import './GraphMapaCard.css';


import { convertToSlug, jsonToFormData } from '../../funcoes';
import { axiosApi } from '../../../../services/axiosInstances';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
// import am4geodata_brazilhigh from "@amcharts/amcharts4-geodata";
import am4themes_spiritedaway from "@amcharts/amcharts4/themes/spiritedaway";
import am4geodata_brazilhigh from "@amcharts/amcharts4-geodata/brazilLow";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Fragment } from 'react';
import NewLoader from '../../NewLoader';

/* Chart code */
am4core.useTheme(am4themes_spiritedaway);
am4core.useTheme(am4themes_animated);
// Themes begin
const GraphExibicoes = ({
    database
}) => {

    const chartExibicoes = am4core.create("chartDiv", am4maps.MapChart);
    // Atribui o 'brazilLow' como o mapa
    // Você pode optar pelo 'brazilHigh', basta alterar aqui e src do script no html
    // Também define que as partes que montam o mapa serão com base no MapPolygonSeries
    chartExibicoes.geodata = am4geodata_brazilhigh;

    const polygonSeriesExibicoes = chartExibicoes.series.push(new am4maps.MapPolygonSeries());
    polygonSeriesExibicoes.data = database;
    polygonSeriesExibicoes.useGeodata = true;
    // Preenche os dados para fazer o mapa de calor
    polygonSeriesExibicoes.heatRules.push({
        property: "fill",
        target: polygonSeriesExibicoes.mapPolygons.template,
        min: chartExibicoes.colors.getIndex(1).brighten(1),
        max: chartExibicoes.colors.getIndex(1).brighten(-0.3)
    });

    // Define as legendas, posição e cores.
    const maxValue = Math.max.apply(Math, database.map(function (o) { return o.value; }));
    const heatLegendExibicoes = chartExibicoes.createChild(am4maps.HeatLegend);
    heatLegendExibicoes.series = polygonSeriesExibicoes;
    heatLegendExibicoes.align = "right";
    heatLegendExibicoes.width = am4core.percent(25);
    heatLegendExibicoes.marginRight = am4core.percent(4);
    heatLegendExibicoes.minValue = 0;
    heatLegendExibicoes.maxValue = maxValue;
    heatLegendExibicoes.valign = "bottom";

    // Set up custom heat map legend labels using axis ranges
    let minRange = heatLegendExibicoes.valueAxis.axisRanges.create();
    minRange.value = heatLegendExibicoes.minValue;
    let maxRange = heatLegendExibicoes.valueAxis.axisRanges.create();
    maxRange.value = heatLegendExibicoes.maxValue;
    maxRange.label.text = maxValue;
    // Configuras os tooltips (texto ao passar o mouse)
    const polygonTemplateExibicoes = polygonSeriesExibicoes.mapPolygons.template;
    polygonTemplateExibicoes.tooltipText = "{name}: {value}";
    polygonTemplateExibicoes.nonScalingStroke = true;
    polygonTemplateExibicoes.strokeWidth = 0.5;
    // Muda a cor do estado ao passar o mouse
    const hsExibicoes = polygonTemplateExibicoes.states.create("hover");
    hsExibicoes.properties.fill = am4core.color("#f9bf29");

    return (
        <div id="chartDiv" style={{ height: "500px" }}></div>
    );
};

const GraphCliques = ({
    database
}) => {

    const chart = am4core.create("chart_cliques", am4maps.MapChart);
    // Atribui o 'brazilLow' como o mapa
    // Você pode optar pelo 'brazilHigh', basta alterar aqui e src do script no html
    // Também define que as partes que montam o mapa serão com base no MapPolygonSeries
    chart.geodata = am4geodata_brazilhigh;

    const polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
    polygonSeries.data = database;
    polygonSeries.useGeodata = true;
    // Preenche os dados para fazer o mapa de calor
    // Faremos com que os menores valores sejam verdes e maiores sejam vermelhos
    polygonSeries.heatRules.push({
        property: "fill",
        target: polygonSeries.mapPolygons.template,
        min: chart.colors.getIndex(1).brighten(1),
        max: chart.colors.getIndex(1).brighten(-0.3)
    });
    // Define as legendas, posição e cores.
    const maxValue = Math.max.apply(Math, database.map(function (o) { return o.value; }));

    const heatLegend = chart.createChild(am4maps.HeatLegend);
    heatLegend.series = polygonSeries;
    heatLegend.align = "right";
    heatLegend.width = am4core.percent(25);
    heatLegend.marginRight = am4core.percent(4);
    heatLegend.minValue = 0;
    heatLegend.maxValue = maxValue;
    heatLegend.valign = "bottom";

    // Set up custom heat map legend labels using axis ranges
    let minRange = heatLegend.valueAxis.axisRanges.create();
    minRange.value = heatLegend.minValue;
    let maxRange = heatLegend.valueAxis.axisRanges.create();
    maxRange.value = heatLegend.maxValue;
    maxRange.label.text = maxValue;
    // Configuras os tooltips (texto ao passar o mouse)
    const polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.tooltipText = "{name}: {value}";
    polygonTemplate.nonScalingStroke = true;
    polygonTemplate.strokeWidth = 0.5;
    // Muda a cor do estado ao passar o mouse
    const hs = polygonTemplate.states.create("hover");
    hs.properties.fill = am4core.color("#f9bf29");
    return (
        <div id="chart_cliques" style={{ height: "500px" }}></div>
    );
};
// Themes end

const GraphMapaCard = ({filtrarView}) => {

    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(true);


    const loadData = async () => {
        try {
            const { data } = await axiosApi.post('cliques-e-views-mapa', filtrarView)
            setData({ clicks: data.cliques, views: data.visualizacoes });
            setIsLoading(false);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        loadData();
    }, []);

    // Define o local e tipo de gráfico

    return (
        <NewLoader overlay isActive={isLoading}>
            <Col className={"graph-card graph-card-size"} sm={6} md={6}>
                <div className="header">
                    <h2>{"Números Exibições"}</h2>
                </div>
                {data.views &&
                    <GraphExibicoes
                        database={data.views} />} 
            </Col>
            <Col className={"graph-card graph-card-size"} sm={6} md={6}>
                <div className="header">
                    <h2>{"Números Toques"}</h2>
                </div>
                {data.clicks &&
                    <GraphCliques
                        database={data.clicks} />} 
            </Col>
        </NewLoader>
    );

};

export default GraphMapaCard;
