import React, { Fragment, useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'
import { CounterCard2, NewLoader } from '../../components/Basicos'
import { formatDate, formatLaravelDate, fragmentarData } from '../../components/Basicos/funcoes'
import { Header } from '../../components/Funcionalidades'
import { axiosApi } from '../../services/axiosInstances'
import FiltroGraficosInstituicao from './_FiltroGraficosInstituicao'
import GraphBarChart from './_GraphBarChart'
import GraphDesafio from './_GraphDesfios'

const GraficosInstituicoes = () => {

    const [isLoading, setIsLoading] = useState(true)
    const [totais, setTotais] = useState({})
    const [gerais, setGerais] = useState({})
    const [categorias, setCategorias] = useState({})
    const [subCat, setSubCat] = useState({})
    const [acertosXresidencia, setAcertosXresidencia] = useState([])
    const [mediaDesafios, setMediaDesafios] = useState({})
    const [params, setParams] = useState({
        instituicao_id: undefined,
        data_inicio: undefined,
        data_final: undefined
    })
    const [graficoPagantes, setGraficoPagantes] = useState([
        { name: "Pagantes", value: 0 },
        { name: "Não Pagantes", value: 100 }
    ])

    const [taxaAcertos, setTaxaAcertos] = useState([
        { name: "Acertos", value: 0 },
        { name: "Erros", value: 100 }
    ])

    const [taxaErros, setTaxaErros] = useState([
        { name: "Erros", value: 0 },
        { name: "Acertos", value: 100 }
    ])

    const loadGraficosSemParametro = () => {
        try {
            Promise.all([
                axiosApi.get('dados_user_instituicao')
            ]).then((dados) => {
                setTotais(dados[0].data)
                const pagante = parseInt(dados[0].data.porcentagem_pagantes)
                const valorNaoPagante = 100 - pagante
                setGraficoPagantes([
                    { name: "Pagantes", value: pagante },
                    { name: "Não Pagantes", value: valorNaoPagante }
                ])
            })
        } catch (error) {
            console.log(error)
        }
    }

    const loadGraficosComParametro = () => {
        try {
            setIsLoading(true);
            Promise.all([
                axiosApi.post('categorias_mais_acertadas', params),
                axiosApi.post('categorias_mais_erradas', params),
                axiosApi.post('subcategorias_mais_acertadas', params),
                axiosApi.post('subcategorias_mais_erradas', params),
                axiosApi.post('taxa_geral_acertos_erros', params),
                axiosApi.post('media_desafios', params),
                axiosApi.post('media_acertos_instituicao_residencia', params)
            ]).then((dados) => {
                setCategorias({
                    certas: dados[0].data.map(el => ({ datakey: el.datakey, quantidade: parseInt(el.valor) })),
                    erradas: dados[1].data.map(el => ({ datakey: el.datakey, quantidade: parseInt(el.valor) }))
                })
                setSubCat({
                    certas: dados[2].data.map(el => ({ datakey: el.datakey, quantidade: parseInt(el.valor) })),
                    erradas: dados[3].data.map(el => ({ datakey: el.datakey, quantidade: parseInt(el.valor) }))
                })
                setGerais(dados[4].data)

                setAcertosXresidencia(dados[6].data.map(el => ({ datakey: el.datakey, Média: parseFloat(el.media_acertos) })))

                const taxa_acertos = parseInt(dados[4].data.taxa_acertos)
                const taxa_erros = parseInt(dados[4].data.taxa_erros)

                setTaxaAcertos([
                    { name: "Acertos", value: taxa_acertos },
                    { name: "Erros", value: taxa_erros }
                ])

                setTaxaErros([
                    { name: "Erros", value: taxa_erros },
                    { name: "Acertos", value: taxa_acertos }
                ])

                setMediaDesafios(dados[5].data)
                setIsLoading(false);
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (
            params.data_final !== undefined &&
            params.data_inicio !== undefined
        ) {
            loadGraficosComParametro()
        }
    }, [params])

    useEffect(() => {
        const id = localStorage.getItem('instituicao_id');
        loadGraficosSemParametro()
        const data = fragmentarData()
        setParams({
            instituicao_id: id,
            data_inicio: `${data.ano}-${data.mesNumero}-01`,
            data_final: formatDate(new Date(), formatLaravelDate)
        })
    }, [])

    return (
        <Fragment>
            <Header instituicao={''} />
            <NewLoader isActive={isLoading} overlay>
                <Row noGutters style={{ justifyContent: 'space-evenly' }}>
                    <FiltroGraficosInstituicao params={params} setParams={setParams} />
                    <CounterCard2
                        sizeCard={11}
                        contagem={totais.residentes ?? 0}
                        legenda={'Total de Residentes'}
                    />
                    {/* <CounterCard2
                        sizeCard={4}
                        sideGraph={true}
                        dataGraph={graficoPagantes}
                        contagem={totais.pagantes ?? 0}
                        legenda={'Total de Pagantes'}
                        porcentagem={totais.porcentagem_pagantes}
                    /> */}
                    <Col sm={12}>
                        <Row style={{ justifyContent: 'space-evenly' }}>
                            <Col sm={6} className="counter-card2" style={{ maxWidth: '600px', minWidth: '500px' }}>
                                <GraphBarChart
                                    titulo={'Categorias Mais Acertadas'}
                                    dados={categorias.certas}
                                />
                            </Col>
                            <Col sm={4}>
                                <CounterCard2
                                    sizeCard={12}
                                    sideGraph={true}
                                    dataGraph={taxaAcertos}
                                    contagem={gerais?.total_acertos ?? 0}
                                    legenda={'Taxa de Acertos'}
                                    sublegenda={'Questões Acertadas'}
                                    porcentagem={taxaAcertos.find(el => el.name == 'Acertos').value}
                                />
                                <CounterCard2
                                    sizeCard={12}
                                    sideGraph={true}
                                    dataGraph={taxaErros}
                                    contagem={gerais?.total_erros ?? 0}
                                    legenda={'Taxa de Erros'}
                                    sublegenda={'Questões Erradas'}
                                    porcentagem={taxaAcertos.find(el => el.name == 'Erros').value}

                                />
                            </Col>
                            <Col sm={10} className="counter-card2">
                                <GraphBarChart
                                    titulo={'Subcategorias Mais Acertadas'}
                                    dados={subCat.certas}
                                    width={850}
                                />
                            </Col>
                            <Col sm={4}>
                                <GraphDesafio
                                    sizeCard={12}
                                    titulo={'Participação em Desafios'}
                                    dados={mediaDesafios}
                                />
                            </Col>
                            <Col sm={6} className="counter-card2" style={{ maxWidth: '600px', minWidth: '500px' }}>
                                <GraphBarChart
                                    titulo={'Categorias Mais Erradas'}
                                    dados={categorias.erradas}
                                />
                            </Col>
                            <Col sm={10} className="counter-card2">
                                <GraphBarChart
                                    titulo={'Subcategorias Mais Erradas'}
                                    dados={subCat.erradas}
                                    width={850}
                                />
                            </Col>
                            <Col sm={10} className="counter-card2">
                                <GraphBarChart
                                    multiBar={true}
                                    barLabel={'Média'}
                                    titulo={'Média de Acertos Gerais X Ano de Residência'}
                                    dados={acertosXresidencia}
                                    width={850}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </NewLoader>
        </Fragment>
    )

}

export default GraficosInstituicoes