import React from 'react';
import { Modal } from 'reactstrap';

import ModalBodyApoiadores from './__ModalApoiadore';
import ModalBodyUsuarios from './__ModalUsuario';

import './ModalList.css';

const ModalList = (props) => {

    return (
        <Modal isOpen={props.isOpen}
            backdrop={props.backdrop}
            setOpenModal={() => props.setOpenModal(false)}
            size='lg'>

            {props.usuario ?
                <ModalBodyUsuarios
                    editarItem={props.editarItem}
                    usuario={props.usuarios.find(usuario => (usuario.id == props.editarItem))}
                    isOpen={props.isOpen}
                    setOpenModal={() => props.setOpenModal(false)}
                    caminho={props.caminho} />
                : <ModalBodyApoiadores
                    editarItem={props.editarItem}
                    isOpen={props.isOpen}
                    setOpenModal={() => props.setOpenModal(false)}
                    caminho={props.caminho}
                    nome="Apoiadores"
                />
            }

        </Modal>
    );
}

export default ModalList;