import React from 'react';
import { AiOutlineExclamationCircle } from 'react-icons/ai';

import './404.css';
function NotFound({ feedback = "Serviço não encontrado ou indisponível" }) {
    const color = "#666";
    return (
        <div className="not-found-container">
            <AiOutlineExclamationCircle color={color} size="3rem"/>
            <h2 style={{ color: color, fontSize: "1.2rem" }}>{feedback}</h2>
        </div>
    );
}
export default NotFound;
