import React, { useEffect, useState } from 'react'
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { Cell, Pie, PieChart } from 'recharts';


function Porcentagem({ dataGraph }) {

    const COLORS = ["#ffa140", "#e5e5e5", "#24b3af"];

    return (
        <PieChart width={180} height={180}>
            <Pie
                data={dataGraph}
                cx={85}
                cy={85}
                innerRadius={65}
                outerRadius={85}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="value"
            >
                {dataGraph.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
            </Pie>
        </PieChart>
    );
}

const GraphDesafio = ({ sizeCard, dados, titulo }) => {
    const [dataGraph, setDataGraph] = useState([
        { name: "Participações", value: 0 },
        { name: "Não Pagantes", value: 100 }
    ])

    useEffect(() => {
        const valorParticipacao = parseInt(dados.media_participacoes)
        const valorNaoParticipacao = 100 - parseInt(dados.media_participacoes)
        setDataGraph([
            { name: "Participações", value: valorParticipacao },
            { name: "Não Pagantes", value: valorNaoParticipacao }
        ])
    }, [dados])


    return (
        <Col sm={{ size: sizeCard ?? 3 }} className="counter-card2">
            <Row className="info">
                <span className="legend">{titulo}</span>
                {/* <span className="count">{contagem}</span>
                <span className="sublegend">{sublegenda}</span> */}
                <span style={{ color: '#ffa140', fontWeight: '600', fontSize: '1.5rem', position: 'relative', left: '0', top: '110px', marginTop: '-35px' }}
                >{dados.media_participacoes ?? 0}%</span>
                <Porcentagem dataGraph={dataGraph} />
            </Row>
            <Row>
                <Col sm={4} style={{ textAlign: 'center' }}>
                    <span style={{ color: '#FF9D3B', fontSize: '1.5rem', fontWeight: '600' }}>{dados.desafios_realizados}</span>
                    <h6 style={{ color: '#c9c9c9'}}>Desafios Relizados</h6>
                </Col>
                <Col sm={4} style={{ textAlign: 'center' }}>
                    <span style={{ color: '#FF9D3B', fontSize: '1.5rem', fontWeight: '600' }}>{dados.participacoes}</span>
                    <h6 style={{ color: '#c9c9c9'}}>Participações</h6>
                </Col>
                <Col sm={4} style={{ textAlign: 'center' }}>
                    <span style={{ color: '#FF9D3B', fontSize: '1.5rem', fontWeight: '600' }}>{dados.media_acertos}</span>
                    <h6 style={{ color: '#c9c9c9'}}>Média de Acertos</h6>
                </Col>
            </Row>
        </Col>
    )

}

export default GraphDesafio