import React from 'react';
import { 
    Col, 
    Card, 
    CardBody, 
    CardSubtitle, 
    CardText, 
    CardTitle, 
    Nav, 
    NavItem,
} from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { convertToSlug } from '../../funcoes';
import { ModalContextConsumer } from '../../../Funcionalidades/modalContext';

import './EditionListItemCardFull.css';

const EditionListItemCardFull = ({
    contexto,
    descricao,
    id,
    subtitulo,
    titulo,
}) => {
    return (
        <Col md={12} className='edition-li-card'>
            <Card>
                <CardBody>
                    <CardTitle>{titulo}</CardTitle>
                    <CardSubtitle>{subtitulo}</CardSubtitle>
                    </CardBody>
                <CardBody>
                    <CardText>{descricao}</CardText>
                    <Nav>
                        <NavItem>
                            <NavLink to={{ pathname: `/${convertToSlug(contexto)}/editar`, hash: `#${id}` }}>
                                Editar
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <ModalContextConsumer>
                                { context => (
                                <a href="#" 
                                    onClick={(e) => {
                                        e.preventDefault();                                      
                                        context.setItem({contexto, id});
                                        context.toggleModal();
                                    }}>
                                    Apagar
                                </a>
                                )}
                            </ModalContextConsumer>
                        </NavItem>
                    </Nav>
                </CardBody>
            </Card>
        </Col>
    );
};

export default EditionListItemCardFull;
