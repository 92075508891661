import React, { PureComponent, Fragment, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'

import { Header, ModalList } from '../../components/Funcionalidades';
import { CounterCard, contagem, legenda, GraphCard, NotFound, SearchFilterGraphic, NewLoader, SearchBarGraphic } from '../../components/Basicos';

import { Col, Row } from 'reactstrap';

import { axiosApi } from '../../services/axiosInstances';
import { formatLaravelDate, formatDate, formatLaravelDateMes } from '../../components/Basicos/funcoes';
import ReactPaginate from 'react-paginate';

import './styles.css'

function ConsultasMesUsuario () {

    const data = new Date();
    let history = useHistory();

    const [filtrarView, setFiltrarView] = useState({
        patrocinador_id: null,
        data_inicio: formatDate( new Date(data.getFullYear(), data.getMonth(), 1), formatLaravelDate),
        data_final: formatDate( new Date(data.getFullYear(), data.getMonth() + 1, 0), formatLaravelDate)
      });
      const graphCardsTitles = ["Números Exibições Por Usuários", "Números Toques Por Usuários"];

      const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        
    }, []);
    function handleClick(event) {
        switch (event.target.name) {
            case 'mes':
                history.push('/consultas/mes');
                break;
            case 'data':
                history.push('/consultas');
                break;
            case 'mes_mapa':
                history.push('/consultas/mes-mapa')
                break;
            case 'mes_usuario':
                history.push('/consultas/mes-usuario')
                break;
            default:
                break;
        }
    }

    return (
        <Fragment>
            <Col md={12} className='back-btn-consulta'>
                <h1 className='header-titulo'>Impressões</h1>
                <button type="button" name="data" class="novo-btn-consulta" onClick={handleClick}>Data</button>
                <button type="button" name="mes" class="novo-btn-consulta" onClick={handleClick}>Mês</button>
                <button type="button" name="mes_mapa" class="novo-btn-consulta" onClick={handleClick}>Mês e Estado</button>
                <button type="button" name="mes_usuario" class="novo-btn-consulta active-consulta" disabled>Mês e Usuário</button>
            </Col>
            <Row noGutters className='grid-container'>

                <NewLoader overlay isActive={isLoading}>
                    <SearchBarGraphic className="search-bar filter-graphic col-md-12"
                        filtrarView={filtrarView}
                        dateTypes={"data"}
                        uf={'false'}
                        setFiltrarView={setFiltrarView}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading} 

                    />
                    {filtrarView.data_final ? '' : setFiltrarView({...filtrarView, ['data_final']: formatDate( new Date(data.getFullYear(), data.getMonth() + 1,0), formatLaravelDate)})}
                    {filtrarView.data_inicio ? '' : setFiltrarView({...filtrarView, ['data_inicio']: formatDate( new Date(data.getFullYear(), data.getMonth(), 1), formatLaravelDate)})}
                    {graphCardsTitles.map((titulo, indice) => (
                        <GraphCard
                            chave={titulo}
                            key={indice}
                            titulo={titulo}
                            graphType={"mes-usuario"}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}   
                            filtrarView={filtrarView} />
                    ))}
                </NewLoader>
            </Row>
        </Fragment>
    )
    
}
export default ConsultasMesUsuario;