import React, { Component, Fragment } from 'react';
import { Row } from 'reactstrap';
import { axiosApi } from '../../services/axiosInstances';
import { EditionListItemCardFull, NotFound, SearchWithFilter, NewLoader } from '../../components/Basicos';
import { Header } from '../../components/Funcionalidades/';
import { logoutWhenTokenExpire, filtrarEstrutura } from '../../components/Basicos/funcoes';

export default class PerguntasListagem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            perguntas: [],
            perguntasFiltradas: [],
            textoFiltro: "",
            isLoading: true
        };
        this.filtrarPor = ['pergunta', 'comentario', 'referencia', 'tipos.descricao', 'subcategorias.descricao'];
    }

    componentDidMount() {
        try {
            this.loadPerguntas()
        } catch (error) {
            console.log(error);
        }
    };

    onChangeTexto = (event) => {
        const textoFiltro = event.target.value;
        const perguntasFiltradas = textoFiltro !== ""
            ? filtrarEstrutura({ estrutura: this.state.perguntas, filtrarPor: this.filtrarPor, textoFiltro })
            : this.state.perguntas.slice();
        this.setState({ textoFiltro, perguntasFiltradas });
    }

    loadPerguntas = async () => {
        try {
            let id = this.props.location.hash.substr(1);
            if (id != 'desafios') {
                const response = await axiosApi.get(`perguntas?categoria_id=${id}`);
                this.setState({ perguntas: response.data, perguntasFiltradas: response.data, textoFiltro: "", isLoading: false });
            }
            else{
                const response = await axiosApi.get(`perguntas?desafios=true`);
                this.setState({ perguntas: response.data, perguntasFiltradas: response.data, textoFiltro: "", isLoading: false });
            }

        } catch (error) {
            console.log(error);
            logoutWhenTokenExpire();
        }
    };

    render() {
        return (
            <Fragment>
                <Header />
                <NewLoader isActive={this.state.isLoading} overlay>
                    <Row noGutters>
                        <SearchWithFilter className="search-bar with-filter"
                            onChangeTexto={this.onChangeTexto}
                            textoFiltro={this.textoFiltro} />
                        {(this.state.perguntasFiltradas.length)
                            ? this.state.perguntasFiltradas.map(pergunta => (
                                <EditionListItemCardFull key={pergunta.id}
                                    contexto="Perguntas"
                                    descricao={`${pergunta.tipos.descricao}, ${pergunta.subcategorias.descricao}`}
                                    id={pergunta.id}
                                    subtitulo={pergunta.referencia}
                                    titulo={pergunta.pergunta}
                                />
                            ))
                            : <NotFound feedback="Sem Perguntas Cadastradas" />}
                    </Row>
                </NewLoader>
            </Fragment>
        )
    }
}
