import React from 'react';
import { NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { IoIosImage, IoIosArrowForward } from 'react-icons/io';

import './PreEditionListItemCard.css';

const PreEditionListItemCard = ({
    descricao,
    icon=false,
    hash='#',
    pathname,
}) => {
    const iconSize = '3rem';
    const iconRender = (typeof icon !== 'string') 
                            ? <IoIosImage size={iconSize} className="icon-left"/> 
                            : <img src={icon} style={{ width: iconSize }}/>;
    return(
    <NavItem className='pre-edition-li-card'>
        <NavLink exact to={{ pathname: pathname, hash: hash }}>
            <div className="container">
                {icon && 
                    iconRender
                }
                {descricao} 

                <IoIosArrowForward size={iconSize} className="icon-right"/>
            </div>
        </NavLink>
    </NavItem>
    );
}

export default PreEditionListItemCard;
