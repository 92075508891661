import React, { useState, useEffect } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Form, Input } from 'reactstrap';
import { IoIosArrowBack } from 'react-icons/io';
import { axiosApi } from '../../../services/axiosInstances';
import { convertToSlug, delay, success, parseDate, formatLaravelDate } from '../../Basicos/funcoes';
import { DateForm } from '../../Basicos';


const ModalDesafio = ({ nomeUrl, titulo, editarItem, desafio, caminho, isOpen, setOpenModal, backdrop }) => {
    const [dadosDesafio, setDadosDesafio] = useState({
        codigo: undefined,
        nome: undefined,
        descricao: undefined,
        data_inicio: undefined,
        hora_inicio: undefined,
        hora_fim: undefined,
        duracao: undefined,
    });

    const [isValid, setIsValid] = useState(false);

    const dateFormStyle = {
        "style": {
            "labelClass": "label-conteudoInferior",
            "elementoClass": "",
            "col": {
                "xs": 12,
                "sm": 12,
                "md": 12,
                "lg": 12,
                "xl": 12
            }
        }
    }
    const onSubmit = async () => {
        const url = editarItem ? `desafios/${editarItem}` : 'desafios';
        try {
            if (desafio)
                await axiosApi.put(url, dadosDesafio);
            else
                await axiosApi.post(url, dadosDesafio);
            setOpenModal(false);
            success(desafio ? 'Alterado com sucesso!' : 'Adicionado com sucesso!');
            await delay(3000);
            window.location.pathname = caminho;
        } catch (error) {
            console.log(error)
        }
    }

    const onChange = (event) => {
        const { name, value } = event.target;
        setDadosDesafio({ ...dadosDesafio, [name]: value })
    };

    const onChangeDate = (nome, valor) => {
        setDadosDesafio({
            ...dadosDesafio,
            data_inicio: valor ?? dadosDesafio.data_inicio,
        })
    };

    useEffect(() => {
        if (isOpen && desafio) {
            setDadosDesafio(desafio[0])
        }
    }, [isOpen]);

    useEffect(() => {
        if (
            dadosDesafio.nome !== undefined &&
            dadosDesafio.descricao !== undefined &&
            dadosDesafio.data_inicio !== undefined &&
            dadosDesafio.hora_inicio !== undefined &&
            dadosDesafio.hora_fim !== undefined
        ) {
            setIsValid(true)
        }
    }, [dadosDesafio])

    return (

        <Modal isOpen={isOpen} backdrop={backdrop} setOpenModal={() => setOpenModal(false)} size='lg'>
            <ModalHeader className="Modal-header">
                <Row noGutters>
                    <Col>
                        <button onClick={() => setOpenModal(false)} className='Back-btn' name='cancelar'><IoIosArrowBack size="3rem" /></button>
                    </Col>
                    <Col md={8}>
                        <h3 className='Modal-titulo'>{titulo}</h3>
                    </Col>
                    <Col>
                        <button onClick={onSubmit}
                            className='Modal-btn align-content-middle'
                            name='salvar'
                            disabled={!isValid}
                        >
                            {desafio ? 'Atualizar' : 'Salvar'}
                        </button>
                    </Col>
                </Row>
            </ModalHeader>
            <ModalBody>
                <Form>
                    <Row id="desafioROW" className='py-2'>
                        <Col>
                            <Input
                                type='text'
                                name='nome'
                                placeholder={'Nome'}
                                onChange={onChange}
                                value={dadosDesafio.nome}
                                valid={dadosDesafio.nome !== undefined}
                                invalid={dadosDesafio.nome === undefined || dadosDesafio.nome === ''} />
                        </Col>
                        <DateForm
                            name="data_inicial"
                            onChange={onChangeDate}
                            dataInicial={{ "placeholder": "Data Desafio", ...dateFormStyle, "diasFuturos": true }}
                            value={{
                                from: parseDate(dadosDesafio.data_inicio, formatLaravelDate),
                            }}
                        />
                    </Row>
                    <Row className='py-2'>
                        <Col>
                            <Input type='textarea'
                                name='descricao'
                                placeholder={'Descricao'}
                                onChange={onChange}
                                value={dadosDesafio.descricao}
                                valid={dadosDesafio.descricao !== undefined}
                                invalid={dadosDesafio.descricao === undefined || dadosDesafio.descricao === ''} />
                        </Col>
                    </Row>
                    <Row className='py-2'>
                        <Col>
                            <Input type="time"
                                name="hora_inicio"
                                placeholder={"Hora Inicio"}
                                onChange={onChange}
                                value={dadosDesafio.hora_inicio}
                                valid={dadosDesafio.hora_inicio !== undefined}
                                invalid={dadosDesafio.hora_inicio === undefined || dadosDesafio.hora_inicio === ''} />
                        </Col>
                        <Col>
                            <Input type="time"
                                name="hora_fim"
                                placeholder={"Hora Fim"}
                                onChange={onChange}
                                value={dadosDesafio.hora_fim}
                                valid={dadosDesafio.hora_fim !== undefined}
                                invalid={dadosDesafio.hora_fim === undefined || dadosDesafio.hora_fim === ''} />
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
        </Modal>
    )
}

export default ModalDesafio;