import React, { Component, Fragment } from 'react';
import { Row } from 'reactstrap';
import { axiosApi } from '../../services/axiosInstances';
import { Header } from '../../components/Funcionalidades';
import { EditionListItemCardFull, NotFound, NewLoader } from '../../components/Basicos';

import { logoutWhenTokenExpire } from '../../components/Basicos/funcoes';
import './styles.css';

export default class Noticias extends Component {
    constructor(props) {
        super(props);
        this.state = {
            noticias: [],
            isLoading: true
        };
    }

    componentDidMount() {
        this.loadNoticias();
    };

    loadNoticias = async () => {
        try {
            const response = await axiosApi.get('noticias');
            this.setState({ noticias: response.data, isLoading:false });
        } catch (error) {
            console.log(error);
            logoutWhenTokenExpire();
        }
    };

    render() {
        return (
            <Fragment>
                <Header />
                <NewLoader overlay isActive={this.state.isLoading}>
                <Row noGutters>
                    { !(this.state.noticias.length) 
                        ? <NotFound feedback="Sem notícias cadastradas"/>
                        : this.state.noticias.map(noticia => (
                            <EditionListItemCardFull key={noticia.id}
                                contexto="Notícias"
                                descricao={noticia.tiposnoticia.descricao}
                                id={noticia.id}
                                subtitulo={noticia.subtitulo}
                                titulo={noticia.titulo}
                            />
                    ))}
                </Row>
                </NewLoader>
            </Fragment>
        )
    }
}
