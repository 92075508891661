import React, { Component, Fragment } from 'react';
import { axiosApi } from '../../services/axiosInstances';
import { Row, Col, Button, CustomInput } from 'reactstrap';
import { Header } from '../../components/Funcionalidades';
import { DateForm, SelectForm } from '../../components/Basicos/';
import { formatLaravelDate, parseDate, jsonToFormData, logoutWhenTokenExpire, success } from '../../components/Basicos/funcoes';
import FileExportForm from '../../components/Basicos/Form/FileExportForm';

import './styles.css';

export default class NovaNoticia extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tipoNoticia: [],
            tipoNoticiaOpcoes: [],
            data_inicial: '',
            data_final: '',
            img_padrao: [],
            img: [],
            youtube_url: null,
            video: false
        };

    }

    componentDidMount() {
        const loadAll = async () => {
            try {
                Promise.all([this.loadTiposNews()]).then();
            } catch (error) {
                console.log(error);
                logoutWhenTokenExpire();
            }
        };

        loadAll();
    };

    loadTiposNews = async () => {
        const response = await axiosApi.get('noticias?tiposnoticia=true');
        this.setState({ tipoNoticia: response.data });
        this.state.tipoNoticia.map(tipo => (
            this.setState({
                tipoNoticiaOpcoes: this.state.tipoNoticiaOpcoes.concat({
                    value: tipo.id,
                    label: tipo.descricao
                })
            })
        ))
        this.setState({ tipoNoticia: '' })
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const cod = new Date()
            const cadastrarNoticia = {
                user_id: 1,
                codigo: cod.toString(),
                titulo: this.state.titulo,
                subtitulo: this.state.subtitulo,
                tiponoticia_id: this.state.tipoNoticia,
                data_inicial: this.state.data_inicial,
                data_final: this.state.data_final,
                img_padrao: this.state.img_padrao[0],
                video_url: this.state.youtube_url,
                imagem: this.state.img[0],
                isImage: !this.state.video
            }
            const response = await axiosApi.post('noticias', jsonToFormData(cadastrarNoticia, 'POST'), {
                headers: { "Content-Type": "multipart/form-data;" }
            }).then(response => {
                success('Adicionado com Sucesso!');
            });
        } catch (error) {
            console.log(error)
        }
        window.history.back();
    };

    onChangeHandler = (nome, valor) => {
        switch (nome) {
            case 'img_padrao':
                  this.setState({ [nome]: valor })
                  break;
            case 'img':
                this.setState({ [nome]: valor })
                break;
            case 'intervalo_data':
                this.setState({ data_inicial: valor.from });
                this.setState({ data_final: valor.to });
                break;
            default:
                this.setState({ [nome]: valor })
                break;
        }
    }

    onChangeInputHtml = (event) => {
        this.onChangeHandler(event.target.name, event.target.value);
    }

    toggle = () => {
        this.setState({ video: !this.state.video });
        if(this.state.video === false){
            this.setState({ youtube_url: null });
        }
        else{
            this.setState({ img: [] });
        }
        
    };


    render() {
        return (
            <Fragment>
                <Header />
                <Row className="conteudo-tamanho align-content-middle">
                    <form className="form-pergunta" onSubmit={this.handleSubmit}>
                        <Col>
                            <input
                                name="titulo"
                                onChange={this.onChangeInputHtml}
                                className="Pergunta-Field"
                                placeholder="Título"
                                required />
                        </Col>
                        <Col>
                            <textarea
                                row="2"
                                name="subtitulo"
                                onChange={this.onChangeInputHtml}
                                className="Pergunta-Field"
                                placeholder="Corpo da Notícia"
                                required />
                        </Col>
                        <Col>
                            <Row className='Pergunta-select-row'>
                                <Col md={4}>
                                    <SelectForm
                                        name={"tipoNoticia"}
                                        options={this.state.tipoNoticiaOpcoes}
                                        onChange={this.onChangeHandler}
                                        placeholder={"Tipo Notícia"}
                                        className={"Pergunta-Select"}
                                        required={this.state.tipoNoticia === '' || this.state.tipoNoticia === undefined}
                                    />
                                </Col>
                                <Col md={8}>
                                    <Row className='picker-row'>
                                        <DateForm
                                            name="intervalo_data"
                                            onChange={this.onChangeHandler}
                                            dataInicial={{ "placeholder": "Data Postagem", ...dateFormStyle }}
                                            dataFinal={{ "placeholder": "Data Exclusão", ...dateFormStyle }}
                                            value={{
                                                from: parseDate(this.state.data_inicial, formatLaravelDate),
                                                to: parseDate(this.state.data_final, formatLaravelDate)
                                            }}
                                        />
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                        <FileExportForm
                                    onChange={ this.onChangeHandler }
                                    valorAtual={ this.state.img_padrao }
                                    name={"img_padrao"}
                                    limiteDeArquivos={1}
                                    subscreverImagens
                                    required
                                />
                        </Col>
                        <Col>
                            <CustomInput onClick={this.toggle}
                                         className="align-content-middle"
                                        type="switch"
                                        id="switchImageVideo"
                                        name="switchImageVideo"
                                        label="Imagem ou Vídeo" />

                            {this.state.video ?
                                <input
                                    name="youtube_url"
                                    onChange={this.onChangeInputHtml}
                                    className="Pergunta-Field"
                                    placeholder="Link do Youtube"
                                />
                                :
                                <FileExportForm
                                    onChange={this.onChangeHandler}
                                    valorAtual={this.state.img}
                                    name={"img"}
                                    limiteDeArquivos={1}
                                    subscreverImagens
                                />
                            }
                        </Col>
                        <Col className="align-content-middle">
                            <button type="submit" className="Login-btn align-content-middle">Publicar</button>
                        </Col>
                    </form>
                </Row>
            </Fragment>
        );
    };
}

const dateFormStyle = {
    "style": {
        "labelClass": "label-conteudoInferior",
        "elementoClass": "",
        "col": {
            "xs": 12,
            "sm": 12,
            "md": 12,
            "lg": 12,
            "xl": 12
        }
    }
}