import React, { Fragment, useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Logout from '../../../pages/Logout';
import Home from '../../../pages/Home';
import Auxiliares from '../../../pages/Auxiliares';
import Categorias from '../../../pages/Categorias';
import Subcategorias from '../../../pages/Subcategorias';
import Perguntas from '../../../pages/Perguntas';
import Pergunta from '../../../pages/Pergunta';
import PerguntasListagem from '../../../pages/PerguntasListagem';
import Tipos from '../../../pages/Tipos';
import Noticias from '../../../pages/Noticias';
import NovaNoticia from '../../../pages/NoticiaNova';
import EditarNoticia from '../../../pages/NoticiaEditar';
import Patrocinadores from '../../../pages/Patrocinadores';
import Usuarios from '../../../pages/Usuarios';
import Consultas from '../../../pages/Consultas';
import ConsultasMes from '../../../pages/Consultas/ConsultasMes';
import ConsultasMesMapa from '../../../pages/Consultas/ConsultasMesMapa';
import ConsultasMesUsuario from '../../../pages/Consultas/ConsultasMesUsuario';
import Cursos from '../../../pages/Cursos';
import Desafios from '../../../pages/Desafios';
import Instituicoes from '../../../pages/Instituicoes';
import Planos from '../../../pages/Planos';
import GraficosInstituicoes from '../../../pages/GraficosInstituicoes';

function RotasAdmin() {
    return (
        <Fragment>
            <Route exact path="/" component={Home} />
            <Route exact path="/perguntas" component={Perguntas} />
            <Route exact path="/perguntas/nova" component={Pergunta} />
            <Route path="/perguntas/editar" component={Pergunta} />
            <Route exact path="/perguntas/listagem" component={PerguntasListagem} />

            <Route exact path="/noticias" component={Noticias} />
            <Route exact path="/noticias/nova" component={NovaNoticia} />
            <Route exact path="/noticias/editar" component={EditarNoticia} />

            <Route exact path="/usuarios" component={Usuarios} />
            <Route exact path="/consultas" component={Consultas} />
            <Route exact path="/consultas/mes" component={ConsultasMes} />
            <Route exact path="/consultas/mes-mapa" component={ConsultasMesMapa} />
            <Route exact path="/consultas/mes-usuario" component={ConsultasMesUsuario} />
            <Route exact path="/apoiadores" component={Patrocinadores} />

            <Route exact path="/auxiliares" component={Auxiliares} />
            <Route exact path="/auxiliares/categorias" component={Categorias} />
            <Route exact path="/auxiliares/subcategorias" component={Subcategorias} />
            <Route exact path="/auxiliares/tipos" component={Tipos} />
            <Route exact path="/auxiliares/cursos" component={Cursos} />
            <Route exact path="/auxiliares/desafios" component={Desafios} />
            <Route exact path="/auxiliares/instituicoes" component={Instituicoes} />
            <Route exact path="/auxiliares/planos-de-assinatura" component={Planos} />

            <Route exact path="/auth/logout" component={Logout} />
            <Redirect to="/" />
        </Fragment>
    )
}

function RotasInstituicao() {
    return (
        <Fragment>
            <Route exact path="/graficos" component={GraficosInstituicoes} />
            <Route exact path="/auth/logout" component={Logout} />
            <Redirect to="/graficos" />
        </Fragment>
    )
}

function Conteudo() {

    const [isInstituicao, setIsInstituicao] = useState(false)

    const nivel = localStorage.getItem('instituicao')

    useEffect(() => {
        console.log(atob(nivel))
        if (atob(nivel) != 3) {
            setIsInstituicao(false)
        } else if (atob(nivel) == 3) {
            setIsInstituicao(true)
        }
    }, [nivel])

    return (
        <Switch>
            {
                isInstituicao ?
                    <RotasInstituicao /> :
                    <RotasAdmin />
            }
        </Switch>
    )
};

export default Conteudo;