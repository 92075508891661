import React, { Fragment, useEffect, useState } from 'react';
import { axiosApi } from '../../services/axiosInstances';
import { Row, Col } from 'reactstrap';
import { FiTrash2, FiEdit } from 'react-icons/fi';
import { Header, ModalInstituicao } from '../../components/Funcionalidades';
import { filtrarEstrutura, logoutWhenTokenExpire } from '../../components/Basicos/funcoes';
import { ModalContextConsumer } from '../../components/Funcionalidades/modalContext';

import './styles.css';
import { SearchWithFilter } from '../../components/Basicos';

const InstituicoesItem = (props) => {
    return (
        <Col md={10} className="Categorias" value={props.id}>
            {props.nome}
            <ModalContextConsumer>
                {context => (
                    <button className="Categorias-btn align-content-middle" name="delete"
                        onClick={(e) => {
                            e.preventDefault();
                            context.setItem({ contexto: 'instituicao', id: props.id });
                            context.toggleModal();
                        }}>
                        <FiTrash2 className='Svg-event-none' />
                    </button>
                )}
            </ModalContextConsumer>
            <button name="edit" id={props.id} className="Categorias-btn align-content-middle" onClick={props.onClick}><FiEdit className='Svg-event-none' /> </button>
        </Col>
    )
}

const Instituicoes = ({ }) => {
    const [openModal, setOpenModal] = useState(false);
    const [idModal, setIdModal] = useState('');
    const [instituicoes, setInstituicoes] = useState([]);
    const [instituicoesFiltradas, setInstituicoesFiltradas] = useState([]);
    const [textoFiltro, setTextoFiltro] = useState('');
    const filtrarPor = ['nome', 'sigla'];

    const onChangeTexto = (event) => {
        const textoFiltro = event.target.value
        const instituicoesFiltradas = textoFiltro !== '' 
        ? filtrarEstrutura({ estrutura: instituicoes, filtrarPor: filtrarPor, textoFiltro })
        : instituicoes.slice(); 
        setInstituicoesFiltradas(instituicoesFiltradas)
        setTextoFiltro(textoFiltro)
    }

    const loadInstituicoes = async () => {
        try {

            const { data } = await axiosApi.get('instituicao');
            setInstituicoes(data.Instituicoes);
            setInstituicoesFiltradas(data.Instituicoes);
        } catch (error) {
            console.log(error);
           // logoutWhenTokenExpire();
        }
    }
    const handleClick = (event) => {
        setOpenModal(true);
        setIdModal(event.target.id);
    }

    useEffect(() => {
        loadInstituicoes()
    }, [])

    return <Fragment>
        <Header />
        <ModalInstituicao titulo={'Editar Instituição'}
            isOpen={openModal}
            backdrop={'static'}
            instituicao={instituicoes && instituicoes.find(instituicao => instituicao.id == idModal)}
            setOpenModal={setOpenModal}
            editarItem={idModal}
            caminho={window.location.pathname}
        />
        <Row noGutters className='grid-container'>
        <SearchWithFilter className="search-bar with-filter"
            onChangeTexto={onChangeTexto}
            textoFiltro={textoFiltro}
        />
        </Row>
        <Row className="conteudo-tamanho">
            {instituicoesFiltradas.map(instituicao => (
                <InstituicoesItem
                    key={instituicao.id}
                    nome={instituicao.nome}
                    id={instituicao.id}
                    onClick={handleClick}
                />
            ))}
        </Row>
    </Fragment>

}

export default Instituicoes