import React from 'react';
import { Col } from 'reactstrap';
import { convertToSlug } from '../../funcoes';
import { AcessosDiarosIcone, PerguntasIcone, UsuariosIcone } from '../../../SVG';

import './CounterCard.css';

function CounterCard({
    // className,
    contagem,
    // icone,
    legenda,
}) {
    return (
        <Col sm={{ size: 3 }} className="counter-card">
            {(convertToSlug(legenda) === 'perguntas') ? // Poderia usar uma prop (icone)
                <PerguntasIcone color="#00b4ae" width="72px" />
            :(convertToSlug(legenda) === 'usuarios') ?
                <UsuariosIcone color="#00b4ae" width="72px" />
            :/*(convertToSlug(titulo) === 'acessos-diarios')*/
                <AcessosDiarosIcone color="#00b4ae" width="72px" />
            }
            <div className="info">
                <span className="count">{contagem}</span>
                <span className="legend">{legenda}</span>
            </div>
        </Col>
    )
}

export default CounterCard;