import React, { Fragment, useState, useEffect } from 'react'
import { Col, Row } from 'reactstrap';
import { Header } from '../../components/Funcionalidades';
import { axiosApi } from '../../services/axiosInstances';
import { FiTrash2, FiEdit } from 'react-icons/fi';
import { ModalContextConsumer } from '../../components/Funcionalidades/modalContext';
import ModalDesafio from '../../components/Funcionalidades/ModalDesafio';

const Desafios = () => {

    const [desafios, setDesafios] = useState();
    const [modal, setModal] = useState({
        openModal: false,
        idModal: ''
    });

    const loadDesafios = async () => {
        const response = await axiosApi.get('desafios');
        setDesafios(response.data.desafios);
    }

    useEffect(() => {
        loadDesafios();
    }, [])

    const handleClick = (event) => {
        setModal({
            ...modal,
            openModal: !modal.openModal,
            idModal: event.target.id
        })
    }

    const DesafiosDB = ({ nome, onClick, id, disabled }) => {
        return (
            <Col md={10} className={`Categorias ${disabled ? 'categoria-disabled' : ''}`} value={id}>
                {nome}
                {
                    <Fragment>
                        <ModalContextConsumer>
                            {context => (
                                <button className="Categorias-btn align-content-middle" name="delete"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        context.setItem({ contexto: 'desafios', id: id });
                                        context.toggleModal();
                                    }}>
                                    <FiTrash2 className='Svg-event-none' />

                                </button>
                            )}
                        </ModalContextConsumer>
                        <button name="edit" id={id} className="Categorias-btn align-content-middle" onClick={onClick}><FiEdit className='Svg-event-none' /> </button>
                    </Fragment>
                }

            </Col>
        )
    }

    return (
        <Fragment>
            <Header />
            <ModalDesafio titulo={'Editar Desafio'}
                isOpen={modal.openModal}
                backdrop={'static'}
                nomeUrl={'Desafios'}
                desafio={desafios && desafios.filter(desafio => desafio.id == modal.idModal)}
                setOpenModal={() => { setModal({ ...modal, openModal: false }) }}
                editarItem={modal.idModal}
                caminho={window.location.pathname}
            />
            <Row className="conteudo-tamanho">
                {desafios && desafios.map(desafio => (
                    <DesafiosDB
                        key={desafio.id}
                        nome={desafio.nome}
                        id={desafio.id}
                        onClick={handleClick}
                        disabled={!desafio.status}
                    />
                ))}
            </Row>
        </Fragment>
    )
}

export default Desafios;