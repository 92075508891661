import React, { Fragment, useState, useEffect } from "react";
import { Col } from 'reactstrap';
import './TableUsersHome.css';
import { useTable, usePagination } from "react-table";
// custom cell component

const TableUsersHome = ({
    colunas, dados}) => {

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize }
    } = useTable({
            columns: colunas,
            data: dados,
            initialState: { pageIndex: 0, pageSize: 5 }
        }, 
        usePagination
    );
    return (
        <Fragment>
            <table {...getTableProps()} className={"table-content"} >
                <thead>
                    {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                        <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                        ))}
                    </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                        {row.cells.map(cell => {
                            return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        })}
                        </tr>
                    )
                    })}
                </tbody>
            </table>
            <div className="pagination align-content-middle paginationin-content">
                <button onClick={() => previousPage()} disabled={!canPreviousPage} className="align-content-middle button-paginationin">
                    {"<"}
                </button>{" "}
                <strong>
                    {pageIndex + 1}  /  {pageOptions.length}
                </strong>{" "}
                <button onClick={() => nextPage()} disabled={!canNextPage} className="align-content-middle button-paginationin">
                    {">"}
                </button>{" "}
            </div>
        </Fragment>
    );
}

export default TableUsersHome;
