import React, { useState, useEffect, Fragment } from 'react';
import { ImageDropzone } from '../../Basicos';
import { FiTrash2, FiEdit } from 'react-icons/fi';
import { ModalContextConsumer } from '../modalContext';
import ModalEdit from '../ModalEdit/ModalEdit';
import { Row, Col, ModalHeader, ModalBody, Form, Input } from 'reactstrap';
import { axiosApi } from '../../../services/axiosInstances';
import { IoIosArrowBack } from 'react-icons/io';

const ModalBodyApoiadores = (props) => {
    const [apoiador, setApoiador] = useState();
    const [imgDB, setImgDB] = useState();
    const [openModalEdit, setOpenModalEdit] = useState(false);

    const loadApoiador = async () => {
        const response = await axiosApi.get(`apoiadores/${props.editarItem}`);
        setApoiador(response.data);
        setImgDB(response.data.image[0].url);
    };

    useEffect(() => {
        if(props.isOpen){
            loadApoiador();
        }
        else if(openModalEdit){
            props.setOpenModal(false);
        }        
    }, [props.isOpen, openModalEdit]);

    const handleClick = async (event) => {
        if(event.target.name === 'edit'){
            setOpenModalEdit(true);
        }
        else if (event.target.name === 'cancelar'){
            props.setOpenModal(false);
        }
    }

    return (
        <Fragment>
            <ModalHeader className="Modal-header">
                <ModalEdit
                    nome={'Apoiadores'}
                    isOpen={openModalEdit}
                    setOpenModal={()=>setOpenModalEdit(false)}
                    editarItem={props.editarItem}
                    apoiador={true}
                    caminho={window.location.pathname}
                />
                <Row noGutters>
                    <Col md={2}>
                        <button onClick={handleClick}
                                className='Back-btn' 
                                name='cancelar'>
                                <IoIosArrowBack
                                size="3rem"
                                className='Svg-event-none' /></button>
                    </Col>
                    <Col md={8}>
                        <h3 className='Modal-titulo'>{apoiador?.nome}</h3>
                    </Col>
                    <Col md={2}>
                    <ModalContextConsumer>
                            {context => (
                                <button className="Categorias-btn align-content-middle" name="delete"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        context.setItem({ contexto: 'apoiadores', id: props.editarItem });
                                        context.toggleModal();
                                    }}>
                                    <FiTrash2 className='Svg-event-none' />
                                </button>
                            )}
                        </ModalContextConsumer>
                        <button name="edit"
                            id={props.id}
                            className="Categorias-btn align-content-middle"
                            onClick={handleClick}>
                            <FiEdit className='Svg-event-none' />
                        </button>
                    </Col>
                </Row>
            </ModalHeader>
            <ModalBody>
                <Form>
                    <Input
                        type='text'
                        className='Modal-input'
                        name='titulo'
                        placeholder={'Site'}
                        value={apoiador?.descricao}
                        disabled
                    />
                    <ImageDropzone imagemBanco={imgDB} disabled={true} caminho={window.location.pathname}/>
                </Form>
            </ModalBody>
        </Fragment>
    );
}

export default ModalBodyApoiadores;