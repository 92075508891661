import React, { useState, useEffect } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Form, Input } from 'reactstrap';
import { IoIosArrowBack } from 'react-icons/io';
import { axiosApi } from '../../../services/axiosInstances';
import { convertToSlug, delay, success, parseDate, formatLaravelDate } from '../../Basicos/funcoes';
import { DateForm } from '../../Basicos';


const ModalCurso = ({ nomeUrl, titulo, editarItem, curso, caminho, isOpen, setOpenModal, backdrop }) => {
    const [dadosCurso, setDadosCurso] = useState({
        codigo: undefined,
        nome: undefined,
        iniciovigencia: undefined,
        fimvigencia: undefined
    });
    const dateFormStyle = {
        "style": {
            "labelClass": "label-conteudoInferior",
            "elementoClass": "",
            "col": {
                "xs": 12,
                "sm": 12,
                "md": 12,
                "lg": 12,
                "xl": 12
            }
        }
    }
    const onSubmit = async () => {
        const url = editarItem ? `cursos/${editarItem}` : 'cursos';
        try {
            if (curso)
                await axiosApi.put(url, dadosCurso);
            else
                await axiosApi.post(url, dadosCurso);
            setOpenModal(false);
            success(curso ? 'Alterado com sucesso!' : 'Adicionado com sucesso!');
            await delay(3000);
            window.location.pathname = caminho;
        } catch (error) {
            console.log(error)
        }
    }

    const onChange = (event) => {
        const { name, value } = event.target;
        setDadosCurso({ ...dadosCurso, [name]: value })
    };

    const onChangeDate = (nome, valor) => {
        setDadosCurso({
            ...dadosCurso,
            iniciovigencia: valor.from ?? dadosCurso.iniciovigencia,
            fimvigencia: valor.to ?? dadosCurso.fimvigencia
        })
    }

    useEffect(() => {
        if (isOpen && curso) {
            setDadosCurso(curso[0])
        }

    }, [isOpen]);

    return (

        <Modal isOpen={isOpen} backdrop={backdrop} setOpenModal={() => setOpenModal(false)} size='lg'>
            <ModalHeader className="Modal-header">
                <Row noGutters>
                    <Col>
                        <button onClick={() => setOpenModal(false)} className='Back-btn' name='cancelar'><IoIosArrowBack size="3rem" /></button>
                    </Col>
                    <Col md={8}>
                        <h3 className='Modal-titulo'>{titulo}</h3>
                    </Col>
                    <Col>
                        <button onClick={onSubmit}
                            className='Modal-btn align-content-middle'
                            name='salvar'
                            disabled={false}
                        >
                            {curso ? 'Atualizar' : 'Salvar'}
                        </button>
                    </Col>
                </Row>
            </ModalHeader>
            <ModalBody>

                <Form>
                    <Row className='py-2'>
                        <Col>
                            <Input
                                type='text'
                                name='codigo'
                                placeholder={'Codigo'}
                                onChange={onChange}
                                value={dadosCurso.codigo}
                                valid={dadosCurso.codigo !== undefined}
                                invalid={dadosCurso.codigo === undefined || dadosCurso.codigo === ''} />
                        </Col>
                        <Col>
                            <Input
                                type='text'
                                name='nome'
                                placeholder={'Nome'}
                                onChange={onChange}
                                value={dadosCurso.nome}
                                valid={dadosCurso.nome !== undefined}
                                invalid={dadosCurso.nome === undefined || dadosCurso.nome === ''} />
                        </Col>
                    </Row>
                    <Row className='picker-row'>
                        <DateForm
                            name="intervalo_data"
                            onChange={onChangeDate}
                            dataInicial={{ "placeholder": "Início Vigência", ...dateFormStyle }}
                            dataFinal={{ "placeholder": "Fim Vigência", ...dateFormStyle }}
                            value={{
                                from: parseDate(dadosCurso.iniciovigencia, formatLaravelDate),
                                to: parseDate(dadosCurso.fimvigencia, formatLaravelDate)
                            }}
                        />
                    </Row>
                </Form>
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
        </Modal>
    )
}

export default ModalCurso;