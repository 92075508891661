import React, { Fragment, useState, useEffect } from 'react';
import { Row } from 'reactstrap';
import { Header } from '../../components/Funcionalidades';
import { CounterCard, GraphCard, contagem, legenda, NewLoader } from '../../components/Basicos';
import { axiosApi } from '../../services/axiosInstances';
import { logoutWhenTokenExpire } from '../../components/Basicos/funcoes';

function Home() {
    const graphCardsTitles = ["Acessos", "Acessos Distintos", "Novos Usuários", "Perguntas Respondidas", "Top 20 Usuário"];

    const [perguntasTotais, setPerguntasTotais] = useState(0);
    const [acessosTotais, setAcessoTotais] = useState(0);
    const [novoUsuariosTotais, setNovoUsuariosTotais] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    const loadUsuario = async () => {
        const response = await axiosApi.get('/usuariosTotal');
        setNovoUsuariosTotais(response.data.usuarios[0].count);
    }
    const loadAcesso = async () => {
        const response = await axiosApi.get('/acessosTotal');
        setAcessoTotais(response.data.acessos[0].count);
    }
    const loadPergunta = async () => {
        const response = await axiosApi.get('/perguntasTotal');
        setPerguntasTotais(response.data.perguntas);
    }

    const promisseAll = async () => {
        try {            
            await Promise.all([loadUsuario(), loadAcesso(), loadPergunta()]);
            setIsLoading(false);
        } catch (error) {
            console.log(error)
            logoutWhenTokenExpire()
        }
    }

    useEffect(() => {
        promisseAll();
    }, []);

    const GraphCards = graphCardsTitles.map((titulo, indice) =>
        <GraphCard chave={titulo} key={indice} titulo={titulo} />
    );
    return (
        <Fragment>
            <Header />
            <NewLoader isActive={isLoading} overlay>
                <Row noGutters>
                    <CounterCard contagem={perguntasTotais} legenda={"Perguntas"} isLoading={isLoading} />
                    <CounterCard contagem={novoUsuariosTotais} legenda={"Usuários"} isLoading={isLoading} />
                    <CounterCard contagem={acessosTotais} legenda={"Acessos Diário"} isLoading={isLoading} />

                    {GraphCards}
                </Row>
            </NewLoader>
        </Fragment>
    )
}
export default Home;
