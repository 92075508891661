import React, { Fragment } from 'react';
import { Col } from 'reactstrap';
import { convertToSlug } from '../../funcoes';
import { AcessosDiarosIcone } from '../../../SVG';

import './CounterCard2.css';
import { Pie, PieChart, Cell } from 'recharts';

function Porcentagem({ dataGraph }) {

    const COLORS = ["#ffa140", "#e5e5e5", "#24b3af"];

    return (
        <PieChart width={100} height={100}>
            <Pie
                data={dataGraph}
                cx={50}
                cy={50}
                innerRadius={30}
                outerRadius={40}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="value"
            >
                {dataGraph.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
            </Pie>
        </PieChart>
    );
}

function CounterCard2({
    sizeCard,
    contagem,
    legenda,
    sublegenda,
    sideGraph,
    dataGraph,
    porcentagem
}) {

    const style = sideGraph ? { display: 'flex' } : {}
    return (
        <Col sm={{ size: sizeCard ?? 3 }} className="counter-card2" style={style}>
            <div className="info">
                <span className="legend">{legenda}</span>
                <span className="count">{contagem}</span>
                <span className="sublegend">{sublegenda}</span>
            </div>
            {sideGraph
                &&
                <div style={{ marginTop: '-35px' }}>
                    <span style={{ color: '#ffa140', fontWeight: '600', fontSize: '1rem', position: 'relative', left: '30px', top: '67px' }}
                    >{porcentagem ?? 0}%</span>
                    <Porcentagem dataGraph={dataGraph} />
                </div>
            }
        </Col >
    )
}

export default CounterCard2;