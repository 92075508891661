import React,{ Fragment }  from 'react'
import Toggle from 'react-toggle'
import { Row, Col, Input} from 'reactstrap';

const FormGeral = ({ dadosInstituicao, onChange }) => {
    return (
        <Fragment>
            <Row className="py-2">
                <Col sm="3">
                    <Input
                        type='text'
                        name='sigla'
                        placeholder={'Sigla'}
                        onChange={onChange}
                        defaultValue={dadosInstituicao.sigla}
                        valid={dadosInstituicao.sigla !== undefined}
                        invalid={dadosInstituicao.sigla === undefined || dadosInstituicao.sigla === ''}
                    />
                </Col>
                <Col sm="9">
                    <Input
                        type='text'
                        name='nome'
                        placeholder={'Nome da Instituição'}
                        onChange={onChange}
                        defaultValue={dadosInstituicao.nome}
                        valid={dadosInstituicao.nome !== undefined}
                        invalid={dadosInstituicao.nome === undefined || dadosInstituicao.nome === ''}
                    />
                </Col>
            </Row>
            <Row className="py-2">
                <Col sm="3">
                    <Input
                        type='text'
                        name='cep'
                        placeholder={'CEP'}
                        onChange={onChange}
                        defaultValue={dadosInstituicao.cep}
                        valid={dadosInstituicao.cep !== undefined}
                        invalid={dadosInstituicao.cep === undefined || dadosInstituicao.cep === ''}
                    />
                </Col>
                <Col sm="6">
                    <Input
                        type='text'
                        name='logradouro'
                        placeholder={'Logradouro'}
                        onChange={onChange}
                        defaultValue={dadosInstituicao.logradouro}
                        valid={dadosInstituicao.logradouro !== undefined}
                        invalid={dadosInstituicao.logradouro === undefined || dadosInstituicao.logradouro === ''}
                    />
                </Col>
                <Col sm="3">
                    <Input
                        type='text'
                        name='numero'
                        placeholder={'Número'}
                        onChange={onChange}
                        defaultValue={dadosInstituicao.numero}
                        valid={dadosInstituicao.numero !== undefined}
                        invalid={dadosInstituicao.numero === undefined || dadosInstituicao.numero === ''}
                    />
                </Col>
            </Row>
            <Row className="py-2">
                <Col sm="6">
                    <Input
                        type='text'
                        name='bairro'
                        placeholder={'Bairro'}
                        onChange={onChange}
                        defaultValue={dadosInstituicao.bairro}
                        valid={dadosInstituicao.bairro !== undefined}
                        invalid={dadosInstituicao.bairro === undefined || dadosInstituicao.bairro === ''}
                    />
                </Col>
                <Col sm="6">
                    <Input
                        type='text'
                        name='complemento'
                        placeholder={'Complemento'}
                        onChange={onChange}
                        defaultValue={dadosInstituicao.complemento}
                        valid={dadosInstituicao.complemento !== undefined}
                        invalid={dadosInstituicao.complemento === undefined || dadosInstituicao.complemento === ''}
                    />
                </Col>
            </Row>
            <Row className="py-2">
                <Col sm="6">
                    <Input
                        type='text'
                        name='cidade'
                        placeholder={'Cidade'}
                        onChange={onChange}
                        defaultValue={dadosInstituicao.cidade}
                        valid={dadosInstituicao.cidade !== undefined}
                        invalid={dadosInstituicao.cidade === undefined || dadosInstituicao.cidade === ''}
                    />
                </Col>
                <Col sm="6">
                    <Input
                        type='text'
                        name='estado'
                        placeholder={'Estado'}
                        onChange={onChange}
                        defaultValue={dadosInstituicao.estado}
                        valid={dadosInstituicao.estado !== undefined}
                        invalid={dadosInstituicao.estado === undefined || dadosInstituicao.estado === ''}
                    />
                </Col>
            </Row>
            <Row className="py-2">
                <Col sm="6">
                    <Input
                        type='text'
                        name='nome_contato'
                        placeholder={'Nome Contato'}
                        onChange={onChange}
                        defaultValue={dadosInstituicao.nome_contato}
                        valid={dadosInstituicao.nome_contato !== undefined}
                        invalid={dadosInstituicao.nome_contato === undefined || dadosInstituicao.nome_contato === ''}
                    />
                </Col>
                <Col sm="6">
                    <Input
                        type='text'
                        name='setor_contato'
                        placeholder={'Setor Contato'}
                        onChange={onChange}
                        defaultValue={dadosInstituicao.setor_contato}
                        valid={dadosInstituicao.setor_contato !== undefined}
                        invalid={dadosInstituicao.setor_contato === undefined || dadosInstituicao.setor_contato === ''}
                    />
                </Col>
            </Row>
            <Row className="py-2">
                <Col sm="6">
                    <Input
                        type='text'
                        name='telefone_contato'
                        placeholder={'Telefone Contato'}
                        onChange={onChange}
                        defaultValue={dadosInstituicao.telefone_contato}
                        valid={dadosInstituicao.telefone_contato !== undefined}
                        invalid={dadosInstituicao.telefone_contato === undefined || dadosInstituicao.telefone_contato === ''}
                    />
                </Col>
                <Col sm="6">
                    <Input
                        type='text'
                        name='whatsapp'
                        placeholder={'Whatsapp'}
                        onChange={onChange}
                        defaultValue={dadosInstituicao.whatsapp}
                        valid={dadosInstituicao.whatsapp !== undefined}
                        invalid={dadosInstituicao.whatsapp === undefined || dadosInstituicao.whatsapp === ''}
                    />
                </Col>
            </Row>
            <Row className="py-2">
                <Col sm="6">
                    <Input
                        type='text'
                        name='email'
                        placeholder={'E-mail'}
                        onChange={onChange}
                        defaultValue={dadosInstituicao.email}
                        valid={dadosInstituicao.email !== undefined}
                        invalid={dadosInstituicao.email === undefined || dadosInstituicao.email === ''}
                    />
                </Col>
                {/* <Col sm="6" Align="Right">
                    STATUS
                    <label>
                        <Toggle
                            defaultChecked={dadosInstituicao.status}
                            name='status'
                            onChange={onChange}
                        />
                    </label>

                </Col> */}
            </Row>
        </Fragment>
    )
}

export default FormGeral