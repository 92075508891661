import React, { Fragment } from 'react';
import { Row, Container, Nav } from 'reactstrap';
import { Header } from '../../components/Funcionalidades';
import { PreEditionListItemCard } from './../../components/Basicos';
import { convertToSlug } from '../../components/Basicos/funcoes';

function Auxiliares() {
    const auxiliares = ["Categorias", "Subcategorias", "Tipos", "Cursos", "Desafios", "Instituicões", "Planos de Assinatura"];
    return (
        <Fragment>
            <Header />
            <Container fluid>
                <Row noGutters>
                    <Nav>
                        {auxiliares.map((auxiliar, indice) => (
                            <PreEditionListItemCard key={indice}
                                descricao={auxiliar}
                                pathname={`/auxiliares/${convertToSlug(auxiliar)}`}
                            />
                        ))}
                    </Nav>
                </Row>
            </Container>
        </Fragment>
    );
}

export default Auxiliares;
