import React, { useState } from "react";
const { Provider, Consumer } = React.createContext();

function ModalContextProvider({ children }) {
    const [isOpen, setIsOpen] = useState(false);
    const [item, setItem] = useState({ contexto: '', id: 0 });

    const toggleModal = () => setIsOpen(!isOpen);
    return (
        <Provider
            value={{
                isOpen: isOpen,
                item: item,
                setItem,
                setIsOpen,
                toggleModal,
            }}
        >
            {children}
        </Provider>
    );
}

export { ModalContextProvider, Consumer as ModalContextConsumer };
