import React, { Fragment, useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { axiosApi } from '../../services/axiosInstances';
import { Row, Col } from 'reactstrap';
import { Header } from '../../components/Funcionalidades';
import { success, logoutWhenTokenExpire, jsonToFormData } from '../../components/Basicos/funcoes';
import { NewLoader, SelectForm } from '../../components/Basicos';

import './styles.css';
import FileExportForm from '../../components/Basicos/Form/FileExportForm';

const Pergunta = () => {

    const [dadosForm, setDadosForm] = useState({
        id: '',
        categoria_id: '',
        subcategoria_id: '',
        tipo_id: '',
        correta: '',
        curso_id: '',
        desafio_id: '',
        corretaOption: '',
        image: [],
        A: undefined,
        B: undefined,
        C: undefined,
        D: undefined,
    })
    const [isLoading, setIsLoading] = useState(true);
    const [categoriasOptions, setCategoriasOptions] = useState();
    const [subCategoriasOptions, setSubCategoriasOptions] = useState();
    const [tiposOptions, setTiposOption] = useState();
    const [cursosOptions, setCursosOptions] = useState();
    const [desafiosOptions, setDesafiosOptions] = useState();
    const [isDisabled, setIsDisabled] = useState(false);

    const loadSelectOptions = async () => {
        try {
            const response = await axiosApi.get('/pergunta-select-options');
            const { categorias, subcategorias, tipos, cursos, desafios } = response.data
            setCategoriasOptions(categorias.map(categoria => ({ value: categoria.id, label: categoria.descricao })))
            setSubCategoriasOptions(subcategorias.map(subcategoria => ({ value: subcategoria.id, label: subcategoria.descricao })))
            setTiposOption(tipos.map(tipo => ({ value: tipo.id, label: tipo.descricao })))
            setCursosOptions(cursos.map(curso => ({ value: curso.id, label: curso.nome })))
            setDesafiosOptions(desafios.map(desafio => ({ value: desafio.id, label: desafio.nome })))
        } catch (error) {
            console.log(error);
            logoutWhenTokenExpire();
        }
    }

    const loadPergunta = async () => {
        let id = window.location.hash.substr(1);
        const { data } = await axiosApi.get('perguntas/' + id);
        const hasImage = data.image[0].url != null ? data.image : [];
        setDadosForm({ ...data, corretaOption: data.corretaOption, image: hasImage })
        setIsLoading(false)
        setIsDisabled(true)
    }

    useEffect(() => {
        if (window.location.hash != '') {
            loadSelectOptions()
            loadPergunta()
        } else {
            loadSelectOptions()
            setIsLoading(false)
        }
    }, [])

    const onSubmit = async () => {
        try {
            dadosForm.image = dadosForm.image[0]
            if (dadosForm.id == '') {
                await axiosApi.post('perguntas', jsonToFormData(dadosForm, 'post'), { headers: { "Content-Type": "multipart/form-data;" } })
                success('Adicionado com Sucesso!')
            } else {
                await axiosApi.post(`perguntas/${dadosForm.id}`, jsonToFormData(dadosForm, 'put'), { headers: { "Content-Type": "multipart/form-data;" } });
                success('Editado com Sucesso!');
            }
        } catch (error) {
            console.log(error)
        }
        window.history.back();
    }

    const Onchange = (nome, valor) => {
        if (nome === 'correta') {
            setDadosForm({
                ...dadosForm,
                [nome]: dadosForm[valor],
                corretaOption: valor
            })
        } else {
            setDadosForm({
                ...dadosForm,
                [nome]: valor
            })
        }
    }
    const corretaOptions = [
        { value: 'A', label: 'Opção A' },
        { value: 'B', label: 'Opção B' },
        { value: 'C', label: 'Opção C' },
        { value: 'D', label: 'Opção D' }
    ]
    return (
        <Fragment>
            <Header />
            <Row className="conteudo-tamanho align-content-middle">
                <NewLoader isActive={isLoading} overlay>
                    <Formik initialValues={{}} onSubmit={onSubmit} >
                        <Form className="form-pergunta">
                            <Col>
                                <Field
                                    name="pergunta"
                                    component="textarea"
                                    className="Pergunta-Field"
                                    placeholder="Pergunta"
                                    onChange={(e) => Onchange(e.target.name, e.target.value)}
                                    value={dadosForm.pergunta || ''}
                                    required
                                />
                            </Col>
                            <Col>
                                <Field
                                    name="comentario"
                                    component="textarea"
                                    className="Pergunta-Field"
                                    placeholder="Comentário"
                                    onChange={(e) => Onchange(e.target.name, e.target.value)}
                                    value={dadosForm.comentario || ''}
                                    required
                                />
                            </Col>
                            <Col>
                                <Field
                                    name="referencia"
                                    className="Pergunta-Field"
                                    placeholder="Referência"
                                    onChange={(e) => Onchange(e.target.name, e.target.value)}
                                    value={dadosForm.referencia || ''}
                                    required
                                />
                            </Col>
                            <Col>
                                <FileExportForm
                                    onChange={Onchange}
                                    valorAtual={dadosForm.image}
                                    name={'image'}
                                    limiteDeArquivos={1}
                                    subscreverImagens
                                />
                            </Col>
                            <Col>
                                <Row className='Pergunta-select-row'>
                                    <Col md={4}>
                                        <SelectForm
                                            name={"categoria_id"}
                                            options={categoriasOptions}
                                            onChange={Onchange}
                                            placeholder={"Categoria"}
                                            className={"Pergunta-Select"}
                                            value={categoriasOptions && categoriasOptions.find(e => (e.value === dadosForm.categoria_id))}
                                            required={dadosForm.categoria_id === ''}
                                            readOnly = {isDisabled}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <SelectForm
                                            name={"subcategoria_id"}
                                            options={subCategoriasOptions}
                                            onChange={Onchange}
                                            placeholder={"Subcategoria"}
                                            className={"Pergunta-Select"}
                                            value={subCategoriasOptions && subCategoriasOptions.find(e => (e.value === dadosForm.subcategoria_id))}
                                            required={dadosForm.subcategoria_id === ''}
                                            readOnly = {isDisabled}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <SelectForm
                                            name={"tipo_id"}
                                            options={tiposOptions}
                                            onChange={Onchange}
                                            placeholder={"Selecione o Tipo"}
                                            className={"Pergunta-Select2"}
                                            value={tiposOptions && tiposOptions.find(e => (e.value === dadosForm.tipo_id))}
                                            required={dadosForm.tipo_id === ''}
                                            readOnly = {isDisabled}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Field
                                    name="A"
                                    className="Pergunta-Field"
                                    placeholder="Opção A"
                                    onChange={(e) => Onchange(e.target.name, e.target.value)}
                                    value={dadosForm.A || ''}
                                    required
                                    disabled = {isDisabled}
                                />
                            </Col>
                            <Col>
                                <Field
                                    name="B"
                                    className="Pergunta-Field"
                                    placeholder="Opção B"
                                    onChange={(e) => Onchange(e.target.name, e.target.value)}
                                    value={dadosForm.B || ''}
                                    required
                                    disabled = {isDisabled}
                                />
                            </Col>
                            <Col>
                                <Field
                                    name="C"
                                    className="Pergunta-Field"
                                    placeholder="Opção C"
                                    onChange={(e) => Onchange(e.target.name, e.target.value)}
                                    value={dadosForm.C || ''}
                                    required
                                    disabled = {isDisabled}
                                />
                            </Col>
                            <Col>
                                <Field
                                    name="D"
                                    className="Pergunta-Field"
                                    placeholder="Opção D"
                                    onChange={(e) => Onchange(e.target.name, e.target.value)}
                                    value={dadosForm.D || ''}
                                    required
                                    disabled = {isDisabled}
                                />
                            </Col>
                            <Col>
                                <Row className='Pergunta-select-row'>
                                    <Col md={4}>
                                        <SelectForm
                                            name={"correta"}
                                            options={corretaOptions}
                                            onChange={Onchange}
                                            placeholder={"Qual a Opção Correta?"}
                                            className={"Pergunta-Select"}
                                            value={corretaOptions && corretaOptions.find(e => (e.value === dadosForm.corretaOption))}
                                            required={dadosForm.correta === ''}
                                            readOnly={
                                                dadosForm.A === undefined ||
                                                dadosForm.B === undefined ||
                                                dadosForm.C === undefined ||
                                                dadosForm.D === undefined
                                                //  || isDisabled
                                            }
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <SelectForm
                                            name={"curso_id"}
                                            options={cursosOptions}
                                            onChange={Onchange}
                                            placeholder={"Curso"}
                                            className={"Pergunta-Select"}
                                            value={cursosOptions && cursosOptions.find(e => (e.value === dadosForm.curso_id))}
                                            required={dadosForm.curso_id === ''}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <SelectForm
                                            name={"desafio_id"}
                                            options={desafiosOptions}
                                            onChange={Onchange}
                                            placeholder={"Desafio"}
                                            className={"Pergunta-Select"}
                                            value={desafiosOptions && desafiosOptions.find(e => (e.value === dadosForm.desafio_id))}
                                        />
                                    </Col>
                                </Row>
                            </Col>

                            <Col className="align-content-middle">
                                <button type="submit" className="Login-btn align-content-middle">Salvar</button>
                            </Col>
                        </Form>
                    </Formik>
                </NewLoader>
            </Row>
        </Fragment>
    )
}
export default Pergunta