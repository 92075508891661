import React, { PureComponent, Fragment } from 'react';
import { Header, ModalList } from '../../components/Funcionalidades';
import { GridCard, NotFound, SearchWithFilter, NewLoader } from '../../components/Basicos';
import { Row } from 'reactstrap';

import { axiosApi } from '../../services/axiosInstances';
import { filtrarEstrutura } from '../../components/Basicos/funcoes';
import ReactPaginate from 'react-paginate';
import './paginate.css';

export default class Usuarios extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            usuarios: [],
            openModal: false,
            idModal: '',
            setOpenModal: false,
            isLoading: true,

            //pesquisa
            usuariosFiltrados: [],
            usuarioDaPagina: [],
            textoFiltro: "",

            //paginate
            offset: 0,
            perPage: 12,
            currentPage: 0
        };
        this.filtrarPor = ['nome', 'email', 'endereco', 'cidade', 'cpf'];
    }

    onChangeTexto = (event) => {
        const textoFiltro = event.target.value;
        const usuariosFiltrados = textoFiltro !== ""
            ? filtrarEstrutura({ estrutura: this.state.usuarios, filtrarPor: this.filtrarPor, textoFiltro })
            : this.state.usuarios.slice();

        this.setState({
            textoFiltro,
            usuariosFiltrados,
            currentPage: 0,
            offset: 0,
            usuarioDaPagina: usuariosFiltrados.slice(0, this.state.perPage),
            pageCount: Math.ceil(usuariosFiltrados.length / this.state.perPage)
        });
    }

    handleClick = async (event) => {
        this.setState({ openModal: true })
        this.setState({ idModal: event.target.id })
    }

    componentDidMount() {
        this.receivedData();
    }

    receivedData() {
        axiosApi.get('users')
            .then(res => {
                const usuarios = res.data;
                const usuarioDaPagina = usuarios.slice(this.state.offset, this.state.offset + this.state.perPage);
                this.setState({
                    pageCount: Math.ceil(usuarios.length / this.state.perPage),
                    usuarioDaPagina,
                    usuarios,
                    usuariosFiltrados: usuarios,
                    isLoading: false,
                });
            });

    }

    handlePageClick = (e) => {
        const currentPage = e.selected;
        const offset = currentPage * this.state.perPage;
        const usuarioDaPagina = this.state.usuariosFiltrados.slice(offset, offset + this.state.perPage);
        this.setState({
            currentPage,
            offset,
            usuarioDaPagina
        });
    };

    formatarData = (data) => {
        return `${data.substr(8, 2)}/${data.substr(5, 2)}/${data.substr(0, 4)}`;
    }

    render() {

        return (
            <Fragment>
                <Header />
                <ModalList isOpen={this.state.openModal}
                    backdrop={true}
                    usuario={true}
                    usuarios={this.state.usuarios}
                    editarItem={this.state.idModal}
                    setOpenModal={() => this.setState({ openModal: false })}
                    size='lg'
                    caminho={window.location.pathname} />
                <Row noGutters className='grid-container'>
                    <NewLoader overlay isActive={this.state.isLoading}>
                        <SearchWithFilter className="search-bar with-filter"
                            onChangeTexto={this.onChangeTexto}
                            textoFiltro={this.textoFiltro}
                        />
                        {this.state.usuarioDaPagina.map(usuario => (
                            <GridCard
                                key={usuario.id}
                                id={usuario.id}
                                className={!usuario.status ? "grid-card grid-status-false" : "grid-card"}
                                titulo={usuario.nome}
                                subtitulo={usuario.email}
                                activeClassName='is-active'
                                dataCriacao={`Data de cadastro: ${this.formatarData(usuario.created_at)}.`}
                                descricao={`Último acesso em: ${this.formatarData(usuario.ultimoacesso)}.`}
                                onClick={this.handleClick}
                            />
                        ))}
                    </NewLoader>
                </Row>
                <Row noGutters className='margin-nogutters-pagination'>
                    <ReactPaginate
                        previousLabel={"Anterior"}
                        nextLabel={"Próximo"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"} />
                </Row>
            </Fragment>
        )
    }
}