import React from 'react';
import { SearchBar } from './_SearchBar';
import { FilterBox } from './_FilterBox';

import './SearchWithFilter.css';

function SearchWithFilter({ className, onChangeTexto, textoFiltro }) {
    return (
        <SearchBar className={className} onChangeTexto={onChangeTexto} textoFiltro={textoFiltro}>
            {/* <FilterBox /> //Botao Filtro - falta implementar*/}
        </SearchBar>
    );
}

export default SearchWithFilter;
