import React from 'react';
import { Col } from 'reactstrap';

import './GridCard.css';

function GridCard({
    titulo,
    subtitulo,
    dataCriacao,
    descricao,
    className,
    onClick,
    id
}) {
    return (
        <Col xs={12} sm={6} md={6} lg={4} xl={4}>
            <div className={className} onClick={onClick} id={id}>
                <h2 className='Svg-event-none text-ellipsis'>{titulo}</h2>
                <h3 className={"wrap-text-without-space Svg-event-none"}>{subtitulo}</h3>
                <p className='Svg-event-none'>{dataCriacao}</p>
                <p className='Svg-event-none'>{descricao}</p>
            </div>
        </Col>
    )
}

export default GridCard;
