import React, { Component, Fragment } from 'react';
import { Header, ModalEdit, ModalList } from '../../components/Funcionalidades';
import { GridCard, SearchWithFilter } from '../../components/Basicos';
import { Row } from 'reactstrap';

import { axiosApi } from '../../services/axiosInstances';
import { logoutWhenTokenExpire } from '../../components/Basicos/funcoes';

export default class Patrocinadores extends Component {
    constructor(props) {
        super(props);

        this.state = {
            patrocinadores: [],
            openModal: false,
            idModal: '',
        };
    }

    componentDidMount() {
        this.loadPatrocinadores();
    }

    loadPatrocinadores = async () => {
        try {
            const response = await axiosApi.get('apoiadores');
            this.setState({ patrocinadores: response.data });
        } catch (error) {
            console.log(error);
            logoutWhenTokenExpire();
        }
    }

    handleClick = (event) => {
        this.setState({ openModal: true })
        this.setState({ idModal: event.target.id })
    }

    render() {
        return (
            <Fragment>
                <Header />
                {/* <ModalEdit nome={'Apoiadores'}
                           isOpen={this.state.openModal}
                           setOpenModal={() => { this.setState({ openModal: false }) }}
                           editarItem={this.state.idModal}
                           patrocinador={true}
                           patrocinadores={this.state.patrocinadores}
                           backdrop={'static'}
                           caminho={window.location.pathname}
                /> */}
                <ModalList caminho={window.location.pathname}
                    isOpen={this.state.openModal}
                    setOpenModal={() => { this.setState({ openModal: false }) }}
                    editarItem={this.state.idModal}
                    apoiador={true}
                    apoiadores={this.state.patrocinadores}
                    backdrop={true}
                    size='lg' />
                <Row noGutters className='grid-container'>
                    {this.state.patrocinadores.map((patrocinador, indice) => (
                        <GridCard
                            key={indice}
                            id={patrocinador.id}
                            className={"grid-card"}
                            titulo={patrocinador.nome}
                            subtitulo={patrocinador.descricao}
                            descricao={`Data do patrocínio: 
                                        ${patrocinador.codigo.substr(8, 2)} de 
                                        ${patrocinador.codigo.substr(4, 3)} de 
                                        ${patrocinador.codigo.substr(11, 4)}.`}
                            onClick={this.handleClick}
                        />
                    ))}
                </Row>
            </Fragment>
        )
    }
}
