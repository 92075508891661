import React, { useState, useEffect } from 'react';
import { Row, Col, Input, InputGroup, InputGroupAddon } from 'reactstrap';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import { SelectForm } from '..';
import { formatoData, formatDate, pt_br, parseDate, formatLaravelDate, formatLaravelDateMes, formatoDataMes, formatJson } from '../funcoes';
import dateFnsFormat from 'date-fns/format';
import { axiosApi } from '../../../services/axiosInstances';

const estados = [
    { uf: 'AC', nome: 'Acre' },
    { uf: 'AL', nome: 'Alagoas' },
    { uf: 'AP', nome: 'Amapá' },
    { uf: 'AM', nome: 'Amazonas' },
    { uf: 'BA', nome: 'Bahia' },
    { uf: 'CE', nome: 'Ceará' },
    { uf: 'DF', nome: 'Distrito Federal' },
    { uf: 'ES', nome: 'Espirito Santo' },
    { uf: 'GO', nome: 'Goiás' },
    { uf: 'MA', nome: 'Maranhão' },
    { uf: 'MS', nome: 'Mato Grosso do Sul' },
    { uf: 'MT', nome: 'Mato Grosso' },
    { uf: 'MG', nome: 'Minas Gerais' },
    { uf: 'PA', nome: 'Pará' },
    { uf: 'PB', nome: 'Paraíba' },
    { uf: 'PR', nome: 'Paraná' },
    { uf: 'PE', nome: 'Pernambuco' },
    { uf: 'PI', nome: 'Piauí' },
    { uf: 'RJ', nome: 'Rio de Janeiro' },
    { uf: 'RN', nome: 'Rio Grande do Norte' },
    { uf: 'RS', nome: 'Rio Grande do Sul' },
    { uf: 'RO', nome: 'Rondônia' },
    { uf: 'RR', nome: 'Roraima' },
    { uf: 'SC', nome: 'Santa Catarina' },
    { uf: 'SP', nome: 'São Paulo' },
    { uf: 'SE', nome: 'Sergipe' },
    { uf: 'TO', nome: 'Tocantins' }
];

const SearchBarGraphic = ({
    className,
    filtrarView,
    setFiltrarView,
    uf,
    dateTypes,
    setIsLoading
}) => {
    const [patrocinadoresOptions, setPatrocinadoresOptions] = useState([]);
    const [estadosOptions, setEstadosOptions] = useState([]);
    const [filtrar, setFiltrar] = useState({});
    const data = new Date();
    const [dateType, setDateType] = useState('');
    const [keyTypeInico, setKeyTypeInico] = useState('');
    const [keyTypefinal, setKeyTypeFinal] = useState('');
    const [dateTypeLaravel, setDateTypeLaravel] = useState('');
    const [dateTypePlaceholderInicio, setDateTypePlaceholderInicio] = useState('');
    const [dateTypePlaceholderFinal, setDateTypePlaceholderFinal] = useState('');



    const selectOnchange = (name, valor) => {
        setFiltrar({
            ...filtrar,
            [name]: valor,
        })
    }

    const onSubmit = (e) => {
        e.preventDefault();
        setFiltrarView({ ...filtrar });
    }


    const locadPatrocinadores = async () => {
        const response = await axiosApi.get('listapatrocinadores');
        const patrocinadorOptions = response.data.map(patrocinador => ({ value: patrocinador.id, label: patrocinador.nome }));
        const estadoOptions = estados.map(elemento => ({ value: elemento.uf, label: elemento.nome }));
        setPatrocinadoresOptions(patrocinadorOptions);
        setEstadosOptions(estadoOptions);
        setIsLoading(false);
    }
    const formatDataType = () => {
        switch (dateTypes) {
            case 'mes':
                setDateType(formatoDataMes);
                setDateTypeLaravel(formatLaravelDateMes);
                setDateTypePlaceholderInicio(`${formatDate(new Date(data.getFullYear(), data.getMonth() - 1), formatoDataMes)}`);
                setDateTypePlaceholderFinal(`${formatDate(new Date(data.getFullYear(), data.getMonth()), formatoDataMes)}`);
                setKeyTypeInico('mes_inicio');
                setKeyTypeFinal('mes_final');
                break;
            case 'data':
                setDateType(formatoData);
                setDateTypeLaravel(formatLaravelDate);
                setDateTypePlaceholderInicio(`${formatDate(new Date(data.getFullYear(), data.getMonth(), 1))}`);
                setDateTypePlaceholderFinal(`${formatDate(new Date(data.getFullYear(), data.getMonth() + 1, 0))}`);
                setKeyTypeInico('data_inicio');
                setKeyTypeFinal('data_final');
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        locadPatrocinadores();
        formatDataType();
    }, []);
    return (
        <div className={className}>
            <InputGroup>
                <Col md={12}>
                    <Row>
                        <Col md={1} className={"label-field"}>
                            <label>Data Inicial:</label>
                        </Col>
                        <Col md={2} className={"field-date"}>
                            <DayPickerInput placeholder={dateTypePlaceholderInicio}
                                name={'data_inicio'}
                                format={dateType}
                                readOnly={true}
                                onDayChange={day => setFiltrar({ ...filtrar, [keyTypeInico]: formatDate(day, dateTypeLaravel) })}
                                formatDate={formatDate}
                                parseDate={parseDate}
                                inputProps={{ required: true }}
                                dayPickerProps={{
                                    locale: 'pt-br', ...pt_br,
                                }}
                            />
                        </Col>
                        <Col md={1} className={"label-field"}>
                            <label>Data Final:</label>
                        </Col>
                        <Col md={2} className={"field-date"}>
                            <DayPickerInput placeholder={dateTypePlaceholderFinal}
                                name={'data_final'}
                                format={dateType}
                                readOnly={true}
                                onDayChange={day => setFiltrar({ ...filtrar, [keyTypefinal]: formatDate(day, dateTypeLaravel) })}
                                formatDate={formatDate}
                                parseDate={parseDate}
                                dayPickerProps={{
                                    locale: 'pt-br', ...pt_br,
                                }}
                            />
                        </Col>
                        <Col md={1} className={"label-field"}>
                            <label>Patrocinador:</label>
                        </Col>
                        <Col md={2} className={"field-select"} style={{ marginLeft: "10px" }}>
                            <SelectForm
                                name={"patrocinador_id"}
                                options={patrocinadoresOptions}
                                onChange={selectOnchange}
                                placeholder={"Patrocinadores"}
                                value={patrocinadoresOptions.find(elemento => (elemento.value == patrocinadoresOptions))}

                            />
                        </Col>
                        <Col md={1} className={"field-button"}>
                            <button type="button" name="novo" onClick={onSubmit} className={"btn-consulta"}>Filtrar</button>
                        </Col>
                    </Row>
                </Col>
                {(uf) !== "false" ?
                    <Col md={12}>
                        <Row>
                            <Col md={1} className={"label-field"}>
                                <label>Estados:</label>
                            </Col>
                            <Col md={2} className={"field-select"}>
                                <SelectForm
                                    name={"uf_crm"}
                                    options={estadosOptions}
                                    onChange={selectOnchange}
                                    placeholder={"Todos"}
                                    value={estadosOptions.find(e => (e.value === estadosOptions))}
                                />
                            </Col>
                        </Row>
                    </Col>
                    : ''
                }
            </InputGroup>


        </div>
    )
}

export default SearchBarGraphic;

