import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col, ModalHeader, ModalBody, Form, Input } from 'reactstrap';
import { IoIosArrowBack } from 'react-icons/io';
import { axiosApi } from '../../../services/axiosInstances';
import { success, delay } from '../../Basicos/funcoes';
import { SelectForm } from '../../Basicos';


const ModalBodyUsuarios = (props) => {
    const [usuario, setUsuario] = useState(props.usuario);
    const [toggle, setToggle] = useState({ status: true });
    const [cursosOptions, setCursosOptions] = useState([]);

    const salvarCurso = async (dadosForm) => {
        try {
            await axiosApi.put('users/' + props.editarItem, dadosForm);
            success('Curso alterado com sucesso');
            await delay(2000);
            window.location.pathname = props.caminho
        } catch (error) {
            console.log(error)
        }
    }
    const handleClick = async (event) => {
        props.setOpenModal(false);
        try {
            if (event.target.name === 'ativar') {
                setToggle(toggle.status = true);
                await axiosApi.put('users/' + props.editarItem, toggle);
                success('Ativado com Sucesso!');
                await delay(3000);
                window.location.pathname = props.caminho
            }
            else if (event.target.name === 'inativar') {
                setToggle(toggle.status = false);
                await axiosApi.put('users/' + props.editarItem, toggle);
                success('Inativado com Sucesso!');
                await delay(3000);
                window.location.pathname = props.caminho
            }

        } catch (error) {
            console.log(error)
        }

    }

    const locadCursos = async () => {
        const response = await axiosApi.get('cursos');
        const { cursos } = response.data;
        const cursoOptions = cursos.map(curso => ({ value: curso.id, label: curso.nome }));
        setCursosOptions(cursoOptions);
    }

    useEffect(() => {
        if (props.isOpen) {
            locadCursos();
        }
    }, [props.isOpen]);

    const mascaraCPF = (n) => {
        return n.substr(0, 3)
            + '.' + n.substr(3, 3)
            + '.' + n.substr(6, 3)
            + '-' + n.substr(9, 2)
    };

    const selectOnchange = (nome, valor) => {
        setUsuario({
            ...usuario,
            [nome]: valor
        });
        salvarCurso({ [nome]: valor });
    }

    return (
        <Fragment>
            <ModalHeader className="Modal-header">
                <Row noGutters>
                    <Col>
                        <button onClick={() => props.setOpenModal(false)}
                            className='Back-btn'
                            name='cancelar'>
                            <IoIosArrowBack
                                className={"Svg-event-none"}
                                size="3rem" />
                        </button>
                    </Col>
                    <Col md={8}>
                        <h3 className='Modal-titulo'>{usuario?.nome}</h3>
                    </Col>
                    <Col>
                        {
                            usuario && usuario.status ?
                                <button onClick={handleClick}
                                    className='Modal-btn align-content-middle'
                                    name='inativar'
                                >Inativar
                            </button> :
                                <button onClick={handleClick}
                                    className='Modal-btn align-content-middle'
                                    name='ativar'
                                >Ativar
                            </button>
                        }
                    </Col>
                </Row>
            </ModalHeader>
            <ModalBody>
                <Row className="conteudo-tamanho align-content-middle">
                    <Form className="form-pergunta">
                        <Row>
                            <Col md={6}>
                                <Input
                                    type='text'
                                    className={"Pergunta-Field"}
                                    name='titulo'
                                    placeholder={'Nome'}
                                    value={usuario?.nome}
                                    disabled
                                />
                            </Col>
                            <Col md={6}>
                                <Input
                                    type='text'
                                    className={"Pergunta-Field"}
                                    name='titulo'
                                    placeholder={'CPF'}
                                    value={usuario && usuario.cpf !== null ? mascaraCPF(usuario?.cpf) : '000.000.000-00'}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Input
                                    type='text'
                                    className={"Pergunta-Field"}
                                    name='titulo'
                                    placeholder={'Endereco'}
                                    value={usuario?.endereco}
                                    disabled
                                />
                            </Col>
                            <Col md={6}>
                                <Input
                                    type='text'
                                    className={"Pergunta-Field"}
                                    name='titulo'
                                    placeholder={'Local de residência'}
                                    value={usuario?.localresidencia}
                                    disabled
                                />
                            </Col>
                            <Col md={6}>
                                <Input
                                    type='text'
                                    className={"Pergunta-Field"}
                                    name='titulo'
                                    placeholder={'Ano de residencia'}
                                    value={usuario?.anoresidencia}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Input
                                    type='text'
                                    className={"Pergunta-Field"}
                                    name='titulo'
                                    placeholder={'CRM'}
                                    value={usuario?.crm}
                                    disabled
                                />
                            </Col>
                            <Col md={6}>
                                <Input
                                    type='text'
                                    className={"Pergunta-Field"}
                                    name='titulo'
                                    placeholder={'Cidade'}
                                    value={usuario?.cidade}
                                    disabled
                                />
                            </Col>
                            <Col md={6}>
                                <Input
                                    type='text'
                                    className={"Pergunta-Field"}
                                    name='titulo'
                                    placeholder={'Estado'}
                                    value={usuario?.estado}
                                    disabled
                                />
                            </Col>
                            <Col md={6}>
                                <SelectForm
                                    name={"curso_id"}
                                    options={cursosOptions}
                                    onChange={selectOnchange}
                                    placeholder={"Curso"}
                                    className={"select-curso-usuario"}
                                    value={cursosOptions.find(curso => (curso.value == usuario?.curso_id))}
                                    required={usuario?.curso_id === ''}
                                />
                            </Col>

                        </Row>
                    </Form>
                </Row>
            </ModalBody>
        </Fragment >
    );


}

export default ModalBodyUsuarios;