import React, { useState, useEffect } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Form, Input, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { IoIosArrowBack } from 'react-icons/io';
import { axiosApi } from '../../../services/axiosInstances';
import { convertToSlug, delay, success, parseDate, formatLaravelDate } from '../../Basicos/funcoes';
import { DateForm } from '../../Basicos';
import classnames from 'classnames';
import './styles.css'
import FuncionalidadesForm from './FuncionalidadesForm';
import ValoresForm from './ValoresForm';
import { unMask_number_format } from '../../Helpers/mask';

const ModalPlanos = ({ nomeUrl, titulo, editarItem, plano, caminho, isOpen, setOpenModal, backdrop }) => {
    const [dadosPlano, setDadosPlano] = useState({
        descricao: undefined,
        status: true,
        planos_funcionalidades: [],
        plano_pagarme: []
    });
    const [tabIndex, setTabIndex] = useState(0); // 0 - Geral, 1 - Funcionalidades, 2- Valores 
    const [funcionalidades, setFuncionalidades] = useState([]);
    const [planosPeriodo, setPlanosPeriodo] = useState([]);

    const loadSelectOptions = async () => {
        try {
            const { data } = await axiosApi.get('planos?selects=true');
            const selectFuncionalidades = data.funcionalidades.map(el => ({ value: el.id, label: el.descricao, tipo: el.tipobool }));
            setFuncionalidades(selectFuncionalidades);
            const selectPlanosPeriodo = data.planosPeriodo.map(el => ({ value: el.id, label: el.descricao, periodo: el.periodo }));
            setPlanosPeriodo(selectPlanosPeriodo)

        } catch (error) {
            console.log(error);
        }

    }

    const onSubmit = async () => {
        const convertNumberPlano = dadosPlano?.plano_pagarme?.map(dado => {
            return {
                ...dado,
                preco: Math.trunc(+unMask_number_format(dado?.preco || '') * 100)
            }
        })
        const dataSubmit = {
            ...dadosPlano,
            plano_pagarme: convertNumberPlano
        }
        const url = editarItem ? `editar_plano_pagarme/${editarItem}` : 'criar_plano_pagarme';
        try {
            if (plano)
                await axiosApi.put(url, dataSubmit);
            else
                await axiosApi.post(url, dataSubmit);
            setOpenModal(false);
            success(plano ? 'Alterado com sucesso!' : 'Adicionado com sucesso!');
            await delay(3000);
            window.location.pathname = caminho;
        } catch (error) {
            console.log(error)
        }
    }

    const onChange = (event) => {
        const { name, value } = event.target;
        setDadosPlano({ ...dadosPlano, [name]: value })
    };

    useEffect(() => {
        if (isOpen && plano) {
            setDadosPlano(plano)
        }
        if (isOpen) {
            loadSelectOptions()
        }
    }, [isOpen]);
    
    return (

        <Modal isOpen={isOpen} backdrop={backdrop} setOpenModal={() => setOpenModal(false)} size='lg'>
            <ModalHeader className="Modal-header">
                <Row noGutters>
                    <Col>
                        <button onClick={() => setOpenModal(false)} className='Back-btn' name='cancelar'><IoIosArrowBack size="3rem" /></button>
                    </Col>
                    <Col md={8}>
                        <h3 className='Modal-titulo'>{titulo}</h3>
                    </Col>
                    <Col>
                        <button onClick={onSubmit}
                            className='Modal-btn align-content-middle'
                            name='salvar'
                            disabled={false}
                        >
                            {plano ? 'Atualizar' : 'Salvar'}
                        </button>
                    </Col>
                </Row>
                <Nav tabs id='plano-tabs'>
                    <NavItem >
                        <NavLink
                            className={'align-content-middle ' + classnames({ active: tabIndex === 0 })}
                            onClick={() => setTabIndex(0)}
                        >
                            Geral
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: tabIndex === 1 })}
                            onClick={() => setTabIndex(1)}
                        >
                            Funcionalidades
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: tabIndex === 2 })}
                            onClick={() => setTabIndex(2)}
                        >
                            Valores
                        </NavLink>
                    </NavItem>
                </Nav>
            </ModalHeader>
            <ModalBody>
                <TabContent activeTab={tabIndex}>
                    <TabPane tabId={0}>
                        <Row>
                            <Col sm="12">
                                <label>Nome do Plano</label>
                                <Input
                                    type='text'
                                    name='descricao'
                                    placeholder={'Nome Do Plano'}
                                    onChange={onChange}
                                    defaultValue={dadosPlano.descricao}
                                    valid={dadosPlano.descricao !== undefined}
                                    invalid={dadosPlano.descricao === undefined || dadosPlano.descricao === ''}
                                />                            
                            </Col>
                        </Row>


                    </TabPane>
                    <TabPane tabId={1}>
                        <FuncionalidadesForm dados={dadosPlano} setDados={setDadosPlano} funcionalidades={funcionalidades} editarItem={editarItem} />
                    </TabPane>
                    <TabPane tabId={2}>
                        <ValoresForm dados={dadosPlano} setDados={setDadosPlano} planosPeriodo={planosPeriodo} editarItem={editarItem} />
                    </TabPane>
                </TabContent>
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
        </Modal>
    )
}

export default ModalPlanos;