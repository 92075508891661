import React, { Component, Fragment } from 'react';
import { axiosApi } from '../../services/axiosInstances';
import { Header } from '../../components/Funcionalidades';
import { Row, Col } from 'reactstrap';
import { FiTrash2, FiEdit } from 'react-icons/fi';
import { logoutWhenTokenExpire } from '../../components/Basicos/funcoes';

import './styles.css';

const TiposDB = (props) => (
    <Col md={10} className="Tipos" value={props.key}>
        {props.descricao}
        <button className="Tipos-btn" name="delete"><FiTrash2 className='Svg-event-none' /></button>
        <button className="Tipos-btn" name="edit"><FiEdit className='Svg-event-none' /></button>
    </Col>
)

export default class Tipos extends Component {

    constructor(props) {
        super(props);

        this.state = {
            tipos: []
        };
    }
    componentDidMount() {
        this.loadTipos();
    };

    loadTipos = async () => {
        try {
            const response = await axiosApi.get('tipos');
            this.setState({ tipos: response.data });
        } catch (error) {
            console.log(error);
            logoutWhenTokenExpire();
        }

    };

    render() {
        return (
            <Fragment>
                <Header />
                <Row >
                    {this.state.tipos.map(tipo => (
                        <TiposDB key={tipo.id} descricao={tipo.descricao} />
                    ))}
                </Row>
            </Fragment>
        )

    }
}