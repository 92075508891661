import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Sidebar, Conteudo, ModalDelete } from '../../components/Funcionalidades';
// import Routes from '../../services/routes';
import './styles.css';

function Main() {
    return (
        <Container fluid style={{ padding: 0 }}>
            <Row noGutters>
                <Col md={2} className='side-menu'>
                    <Sidebar />
                </Col>
                <Col md={10} className='main-header'>
                    <Conteudo />
                </Col>
            </Row>

            <ModalDelete />
        </Container>
    );
};


export default Main;
