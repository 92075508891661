import React, { useState, useEffect } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Form, Input } from 'reactstrap';
import { IoIosArrowBack } from 'react-icons/io';
import { ImageDropzone } from '../../../components/Basicos';
import { axiosApi } from '../../../services/axiosInstances';
import { convertToSlug, jsonToFormData, delay, success } from '../../Basicos/funcoes';
import { SelectForm } from '../../Basicos'
import './styles.css';

const ModalEdit = (props) => {
    const [categoriaOptions, setCategoriaOptions] = useState();
    const [valorTitulo, setValorTitulo] = useState();
    const [valorSubtitulo, setValorSubtitulo] = useState();
    const [valorCategoria, setValorCategoria] = useState(0);
    const [img, setImg] = useState();
    const [imgDB, setImgDB] = useState();

    const handleClick = async (event) => {
        const { name } = event.target;
        const { nome } = props;

        if (name === 'salvar') {
            //Preparar envio de dados:
            const formData = jsonToFormData({
                descricao: valorTitulo
            }, "PUT");

            if (img) {
                formData.append('icone', img, img.name);
                formData.append('img_dir', img.name);
            }

            switch (convertToSlug(nome)) {
                case 'subcategorias':
                    formData.append('categoria_id', valorCategoria);
                    break;
                case 'apoiadores':
                    const cod = new Date();
                    formData.append('user_id', 1);
                    formData.append('nome', valorTitulo);
                    formData.append('descricao', valorSubtitulo); //AKA site
                    formData.append('codigo', cod.toString()); // AKA descricao ou data_patrocinio
                    formData.append('status', true);
                    break;
                default:
                    break;
            }

            try {                
                await axiosApi.post(convertToSlug(nome) + `/${props.editarItem}`, formData);
                props.setOpenModal(false);
                success('Alterado com Sucesso!');
                await delay(3000);
                window.location.pathname = props.caminho;
            } catch (error) {
                console.log(error)
            }
        }
        props.setOpenModal(false);
    };

    const onChange = (event) => {
        const { name, value } = event.target;
        if (name === 'titulo')
            setValorTitulo(value);
        else if (name === 'subtitulo')
            setValorSubtitulo(value);
    };

    const selectOnchange = (nome, valor) => {
        setValorCategoria(valor);
    }

    const loadCategorias = async () => {
        const response = await axiosApi.get(`categorias?subcategoria=true`);
        setCategoriaOptions(response.data)
    };

    const loadCategoriasCadastrada = async () => {
        try {
            const response = await axiosApi.get(`categorias/${props.editarItem}`)
            setValorTitulo(response.data.descricao)
            setImgDB(response.data.image[0].url)
        } catch (error) {
            console.log(error)
        }
    }

    const loadSubcategoriaCadastrada = async () => {
        try {
            const response = await axiosApi.get(`subcategorias/${props.editarItem}`)
            setValorTitulo(response.data.descricao);
            setValorCategoria(response.data.categoria_id);
            setImgDB(response.data.image[0].url);
        } catch (error) {
            console.log(error)
        }
    }

    const loadApoiador = async () => {
        try {
            const response = await axiosApi.get(`apoiadores/${props.editarItem}`)
            setValorTitulo(response.data.nome);
            setValorSubtitulo(response.data.descricao);
            setImgDB(response.data.image[0].url);
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        if (props.isOpen) {
            switch (props.nome) {
                case 'Categorias':
                    loadCategoriasCadastrada();
                    break;
                case 'Subcategorias':
                    loadCategorias();
                    loadSubcategoriaCadastrada();
                    break;
                case 'Apoiadores':
                    loadApoiador();
                    break;
                default:
                    break;
            }
        }
        
    }, [props.isOpen]);

    return (

        <Modal isOpen={props.isOpen} backdrop={props.backdrop} setOpenModal={() => props.setOpenModal(false)} size='lg'>
            <ModalHeader className="Modal-header">
                <Row noGutters>
                    <Col>
                        <button onClick={handleClick} className='Back-btn' name='cancelar'><IoIosArrowBack size="3rem" /></button>
                    </Col>
                    <Col md={8}>
                        <h3 className='Modal-titulo'>{props.titulo}</h3>
                    </Col>
                    <Col>
                        <button onClick={handleClick}
                            className='Modal-btn align-content-middle'
                            name='salvar'
                            disabled={props.nome === 'Apoiadores' ?
                                valorTitulo === undefined || valorSubtitulo === undefined
                                : valorTitulo === undefined}>
                            Atualizar
                        </button>
                    </Col>
                </Row>
            </ModalHeader>
            <ModalBody>
                <Form>
                    <Input
                        type='text'
                        className='Modal-input'
                        name='titulo'
                        placeholder={'Nome'}
                        value={valorTitulo}
                        onChange={onChange}
                        valid={valorTitulo !== undefined}
                        invalid={valorTitulo === undefined || valorTitulo === ''} />

                    {(props.subcategoria) ?
                        <SelectForm
                            name={"categorias"}
                            options={categoriaOptions}
                            value={valorCategoria&&categoriaOptions&&categoriaOptions.find(elemento => (elemento.value === valorCategoria))}
                            onChange={selectOnchange}
                            menuPortalTarget={document.body}
                            placeholder={"Selecione a Categoria"}
                            className={"Modal-input"} />
                        :
                        (props.apoiador) &&
                        <Input
                            type='text'
                            className='Modal-input'
                            name='subtitulo'
                            placeholder={'Site'}
                            onChange={onChange}
                            value={valorSubtitulo}
                            valid={valorSubtitulo !== undefined}
                            invalid={valorSubtitulo === undefined || valorSubtitulo === ''} />
                    }

                    <ImageDropzone setImg={setImg} imagemBanco={imgDB} />
                </Form>
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
        </Modal>
    )
}

export default ModalEdit;