import { id } from 'date-fns/locale';
import React from 'react'
import { useState } from 'react';
import { Col, Form, Input, Row, Button } from 'reactstrap';
import { SelectForm } from '../../Basicos';
import { FiTrash2, FiPlus } from 'react-icons/fi';
import { useEffect } from 'react';
import { mask_format_number_static, number_format } from '../../Helpers/mask';




const ValoresForm = ({ dados, setDados, planosPeriodo, editarItem }) => {
    const [dadosForm, setDadosForm] = useState([{
        componentId: '_' + Math.random().toString(36).substr(2, 9)
    }]);

    useEffect(() => {
        if (dados.plano_pagarme.length != 0) {
           const planosPeriodo = dados?.plano_pagarme?.map(el => {
                return {
                    ...el,
                    componentId: el.id,
                    preco: mask_format_number_static((el?.preco)/100, 2, '.',','),

                }

            })
            setDadosForm(planosPeriodo);
        }
    }, [])

    const addItemForm = (e) => {
        e.preventDefault();
        const fakeId = '_' + Math.random().toString(36).substr(2, 9),
            prevQuantidade = dadosForm.slice()
        prevQuantidade.push({ componentId: fakeId })
        setDadosForm(prevQuantidade)
    }

    const removeItemForm = (componentId) => {
        if (dadosForm.length > 1) {
            const dadosFiltrados = dadosForm.filter(el => el.componentId != componentId)
            setDadosForm(dadosFiltrados)
            setDados({ ...dados, plano_pagarme: dadosFiltrados })
        }
    }

    const onChange = (name, value, componentId) => {
        const dadosFormPrev = dadosForm.slice();
        const formIndex = dadosForm.findIndex(el => el.componentId == componentId)
        if(name === 'preco'){
            dadosFormPrev[formIndex] = {
                ...dadosFormPrev[formIndex],
                [name]: number_format(value, 2)
            }
            setDadosForm(dadosFormPrev)
            setDados({ ...dados, plano_pagarme: dadosFormPrev })
        }else{
             dadosFormPrev[formIndex] = {
                ...dadosFormPrev[formIndex],
                [name]: value
            }
            setDadosForm(dadosFormPrev)
            setDados({ ...dados, plano_pagarme: dadosFormPrev })

        }
    }

    const tratamentoInput = (value) => value === 'R$ ,' ? '' : value;

    return <Form>
        {dadosForm.map((el) =>
        (<Row key={el.componentId} className='align-content-middle'>
            <Col className="col-4">
                <SelectForm
                    indice={el.componentId}
                    name={"periodo_id"}
                    options={planosPeriodo}
                    value={planosPeriodo && planosPeriodo.find(elemento => (elemento.value === el.periodo_id))}
                    onChange={onChange}
                    menuPortalTarget={document.body}
                    placeholder={"Escolha o período"}
                    className={"select-curso-usuario"}
                />
            </Col>
            <Col className="col-3">
                <Input
                    id={el.componentId}
                    type='text'
                    name='dias'
                    placeholder={"dias"}
                    onChange={(e) => onChange(e.target.name, e.target.value, e.target.id)}
                    value={planosPeriodo && planosPeriodo.find(elemento => (elemento.value === el.periodo_id))?.periodo}
                />
            </Col>
           <Col className="col-3">
                <Input
                    className='style-input-mask'
                    name='preco'
                    id={el.componentId}
                    type='text'
                    onChange={(e) => onChange(e.target.name, e.target.value, e.target.id)}
                    value={tratamentoInput(el.preco)}
                />
            </Col>
            <Col className="col-auto">
                <button name="remove"
                    className="Categorias-btn align-content-middle"
                    onClick={(e) => {
                        e.preventDefault()
                        removeItemForm(el.componentId)
                    }
                    }>
                    <FiTrash2 size={50} className='feather' />
                </button>
                <button name="add"
                    className="Categorias-btn align-content-middle"
                    onClick={addItemForm}>
                    <FiPlus size={50} className='feather' />
                </button>
            </Col>
        </Row>
        )
        )}


    </Form>

}

export default ValoresForm