import React, { Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import { Col } from 'reactstrap'
import './styles.css'
import quizOrtopedia from '../../../img/quizOrtopedia.png'
import { IoIosArrowBack } from 'react-icons/io';

import { ModalNovo } from '../index'
import { useState } from 'react'
import ModalCurso from '../ModalCurso'
import ModalDesafio from '../ModalDesafio'
import ModalPlanos from '../ModalPlanos';
import ModalInstituicao from '../ModalInstituicao'

function Header({
    handleSubmit,
    instituicao
}) {
    let history = useHistory();

    const [openModal, setOpenModal] = useState(false);

    function handleClick(event) {
        switch (event.target.name) {
            case 'novaPergunta':
                history.push('/perguntas/nova');
                break;
            case 'novaNoticia':
                history.push('/noticias/nova');
                break;
            case 'novo':
                setOpenModal(true);
                break;
            default:
                break;
        }
    }

    const BotaoBack = () => {
        window.history.back();
    }

    const BotaoNovo = () => {
        switch (window.location.pathname) {
            case '/quizmed/perguntas':
                return (
                    <button type="button"
                        name="novaPergunta"
                        className="Novo-btn"
                        onClick={handleClick}>Nova Questão
                    </button>
                );
            case '/quizmed/perguntas/nova':
                return '';
            case '/quizmed/noticias':
                return (<button type="button" name="novaNoticia" className="Novo-btn" onClick={handleClick}>Nova Notícia</button>);
            case '/quizmed/noticias/nova':
                return '';
            case '/quizmed/auxiliares/instituicoes':
                return (
                    <Fragment>
                        <ModalInstituicao titulo={'Nova Instituição'}
                            isOpen={openModal}
                            backdrop={'static'}
                            nome={'Instituicoes'}
                            setOpenModal={setOpenModal}
                            caminho={window.location.pathname} />
                        <button type="button" name="novo" className="Novo-btn btn-patrocinador" onClick={handleClick}>Nova Instituição</button>
                    </Fragment>
                );
            case '/quizmed/apoiadores':
                return (
                    <Fragment>
                        <ModalNovo
                            titulo={'Novo Patrocinador'}
                            isOpen={openModal}
                            backdrop={'static'}
                            nome={'Apoiadores'}
                            setOpenModal={setOpenModal}
                            apoiador={true}
                            caminho={window.location.pathname}
                        />
                        <button type="button" name="novo" className="Novo-btn btn-patrocinador" onClick={handleClick}>Novo Patrocinador</button>
                    </Fragment>
                );
            case '/quizmed/auxiliares/categorias':
                return (
                    <Fragment>
                        <ModalNovo
                            titulo={'Nova Categoria'}
                            isOpen={openModal}
                            backdrop={'static'}
                            nome={'Categorias'}
                            setOpenModal={setOpenModal}
                            caminho={window.location.pathname}
                        />
                        <button type="button" name="novo" className="Novo-btn btn-patrocinador" onClick={handleClick}>Nova Categoria</button>
                    </Fragment>
                );
            case '/quizmed/auxiliares/subcategorias':
                return (
                    <Fragment>
                        <ModalNovo
                            titulo={'Nova Subcategoria'}
                            isOpen={openModal}
                            backdrop={'static'}
                            nome={'Subcategorias'}
                            subcategoria={true}
                            setOpenModal={setOpenModal}
                            caminho={window.location.pathname}
                        />
                        <button type="button" name="novo" className="Novo-btn" onClick={handleClick} style={{ width: "80%" }}>Nova Subcategoria</button>
                    </Fragment>
                );
            case '/quizmed/auxiliares/cursos':
                return (
                    <Fragment>
                        <ModalCurso
                            titulo={'Novo Curso'}
                            isOpen={openModal}
                            backdrop={'static'}
                            nome={'Cursos'}
                            setOpenModal={setOpenModal}
                            caminho={window.location.pathname}
                        />
                        <button type="button" name="novo" className="Novo-btn" onClick={handleClick} style={{ width: "80%" }}>Novo</button>
                    </Fragment>
                );
            case '/quizmed/auxiliares/desafios':
                return (
                    <Fragment>
                        <ModalDesafio
                            titulo={'Novo Desafio'}
                            isOpen={openModal}
                            backdrop={'static'}
                            nome={'Desafios'}
                            setOpenModal={setOpenModal}
                            caminho={window.location.pathname}
                        />
                        <button type="button" name="novo" className="Novo-btn" onClick={handleClick} style={{ width: "80%" }}>Novo</button>
                    </Fragment>
                );
            default:
                return '';
        }
    }

    const HeaderTop = () => {

        switch (window.location.pathname) {
            case '/quizmed/auxiliares/categorias':
                return <Fragment><Col className='Back-btn'><IoIosArrowBack size="3rem" onClick={BotaoBack} /></Col><h1>Categorias</h1></Fragment>
            case '/quizmed/auxiliares/subcategorias':
                return <Fragment><Col className='Back-btn'><IoIosArrowBack size="3rem" onClick={BotaoBack} /></Col><h1>Subcategorias</h1></Fragment>
            case '/quizmed/auxiliares/tipos':
                return <Fragment><Col className='Back-btn'><IoIosArrowBack size="3rem" onClick={BotaoBack} /></Col><h1>Tipos</h1></Fragment>
            case '/quizmed/auxiliares/cursos':
                return <Fragment><Col className='Back-btn'><IoIosArrowBack size="3rem" onClick={BotaoBack} /></Col><h1>Cursos</h1></Fragment>
            case '/quizmed/auxiliares/desafios':
                return <Fragment><Col className='Back-btn'><IoIosArrowBack size="3rem" onClick={BotaoBack} /></Col><h1>Desafios</h1></Fragment>
            case '/quizmed/perguntas/listagem':
                return <Fragment><Col className='Back-btn'><IoIosArrowBack size="3rem" className='Back-btn' onClick={BotaoBack} /></Col><h1>Perguntas</h1></Fragment>
            case '/quizmed/perguntas/editar':
                return <Fragment><Col className='Back-btn'><IoIosArrowBack size="3rem" className='Back-btn' onClick={BotaoBack} /></Col><h1>Editar Pergunta</h1></Fragment>
            case '/quizmed/perguntas/nova':
                return <Fragment><Col className='Back-btn'><IoIosArrowBack size="3rem" className='Back-btn' onClick={BotaoBack} /></Col><h1>Nova Pergunta</h1></Fragment>
            case '/quizmed/noticias/nova':
                return <Fragment><Col className='Back-btn'><IoIosArrowBack size="3rem" className='Back-btn' onClick={BotaoBack} /></Col><h1>Nova Notícia</h1></Fragment>
            case '/quizmed/noticias/editar':
                return <Fragment><Col className='Back-btn'><IoIosArrowBack size="3rem" className='Back-btn' onClick={BotaoBack} /></Col><h1>Editar Notícia</h1></Fragment>
            case '/quizmed/graficos':
                return <Fragment><img src={quizOrtopedia} width="360" alt="nome quizmed cabeçalho" /><h1 style={{ marginLeft: '2rem' }}>{instituicao}</h1></Fragment>
            default:
                return <img src={quizOrtopedia} width="360" alt="nome quizmed cabeçalho"></img>
        }
    }

    return (
        <header id='main-header'>
            <Col md={6} className="middle">
                <HeaderTop />
            </Col>
            <Col md={4}>
                <BotaoNovo />
            </Col>
        </header>
    )
}

export default Header;