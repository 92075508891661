import React, {Fragment} from 'react';
import { useDropzone } from 'react-dropzone';
import { Col, Label, FormGroup, FormFeedback } from "reactstrap";
import PropTypes from 'prop-types';
// import uploadIcon from "../../images/ic_imagem.png";
// import MenuTable from '../../Menu/menuTable';

import { TiDocument } from 'react-icons/ti';

//comparar tamanho do array files , ver quantos falta e dar um slice no array accept, caso esteja lotado manda o aviso
function FileExportForm({ 
    accept,
    placeholder,
    style, 
    label, 
    preview, 
    valorAtual,
    onChange, 
    name, 
    limiteDeArquivos,
    minSize, 
    maxSize,
    subscreverImagens,
}) {

    const files = valorAtual;

    const onDrop = (acceptedFiles) => {
        const quantidadeRestante = limiteDeArquivos - files.length;
        const listaImagens = subscreverImagens ? acceptedFiles.slice(0, limiteDeArquivos) : [...files, ...acceptedFiles.slice(0, quantidadeRestante)];
        onChange(name, listaImagens);
    }

    const defineImageURL = (file) => file instanceof File ? URL.createObjectURL(file) : file.url;

    const isImage = (file) => file instanceof File ? file.type.includes('image') : file.tipo_mime.includes('image');

    const { fileRejections, getRootProps, getInputProps } = useDropzone({
        accept: accept,
        maxSize: maxSize,
        minSize: minSize,
        onDrop
    });

    const aceitos = files.map((file, indice) => {
        
        const changeOnClick = () => {
            const auxFiles = files.slice();
            auxFiles.splice(indice, 1);
            onChange(name, auxFiles); 
        }
        const miniatura = <img src={defineImageURL(file)} className="img-file" />;
        const iconePadrao =  <TiDocument className="menuIcons img-file"/>;
        return (
            <Fragment key={indice}>
                {(preview && isImage(file)) ? miniatura : (iconePadrao)}
                <span> { file instanceof File ? file.name : file.nome } </span>
                <div className="fakeBtt">Trocar foto</div>
            </Fragment>
        )
    });

    const rejeitados = fileRejections.map((file, indice) => (
        <FormFeedback style={{ display: "block" }} key={indice}>
            {  `Arquivo ${file instanceof File ? file.name : file.nome}
               é inválido verifique o tamanho ou nome!`
            }
        </FormFeedback>
    ));
    
    const cabecalho = (
        <Fragment key={name}>
            <h3>Adicione uma Imagem</h3>
            <p className="m-0" >Clique no botão abaixo ou arraste e solte uma imagem aqui.</p>
            <div className="fakeBtt">Adicionar foto</div>
        </Fragment>
    );

    return (
            <div {...getRootProps({ className: 'dropzone' })}>
                {(limiteDeArquivos !== files.length) && 
                    cabecalho
                }
                {aceitos}
                {rejeitados}
                <input {...getInputProps()} />
            </div>
    );
}

const colShape = {
    xs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    md: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    lg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    xl: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

const styleShape = {
    labelClass: PropTypes.string,
    elementoClass: PropTypes.string,
    col: PropTypes.shape(colShape)
}

FileExportForm.defaultProps = {
    mask: undefined,
    preview: true,
    valorAtual: [],
    limiteDeArquivos: 1,
    style: {
        labelClass: "label-conteudoInferior",
        elementoClass: "",
        col: {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12
        }

    }
};

FileExportForm.propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    style: PropTypes.shape(styleShape)
}

export default FileExportForm;