import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Form } from 'reactstrap';
import { IoIosArrowBack } from 'react-icons/io';
import './styles.css'
import { axiosApi } from '../../../services/axiosInstances';
import { convertToSlug, success, delay } from '../../Basicos/funcoes'
import { ModalContextConsumer } from '../../Funcionalidades/modalContext';

const ModalDelete = () => {
  const handleClick = async (context) => {
    try {
      const { contexto, id } = context.item;
      context.setIsOpen(false);
      await axiosApi.delete(`/${convertToSlug(contexto)}/${id}`);
      success('Deletado com sucesso!');
    } catch (error) {
      console.log(error);
    }
    await delay(3000);
    window.location.reload();
  };
  return (
    <ModalContextConsumer>
      {context => (
        <Modal
          backdrop='static'
          isOpen={context.isOpen}
          size='lg'
        >
          <ModalHeader className="Modal-header">
            <Row noGutters>
              <Col>
                <button
                  className='Back-btn'
                  name='back'
                  onClick={context.toggleModal}
                >
                  <IoIosArrowBack size="3rem" />
                </button>
              </Col>
              <Col md={8}>
                <h3 className='Modal-titulo'>Deletar Item</h3>
              </Col>
            </Row>
          </ModalHeader>
          <ModalBody>
            <p>
              Deseja realmente apagar?<br/>
              Esta operação não poderá ser desfeita.
            </p>
          </ModalBody>
          <ModalFooter>
            <Col>
              <button
                className='Modal-btn align-content-middle'
                name='cancel'
                onClick={context.toggleModal}
              >
                Cancelar
            </button>
            </Col>
            <Col>
              <button
                className='Modal-btn align-content-middle'
                name='delete'
                onClick={() => handleClick(context)} 
                >
                Apagar
            </button>
            </Col>
          </ModalFooter>
        </Modal>
      )}
    </ModalContextConsumer>
  );
}

export default ModalDelete;
