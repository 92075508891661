import React, { useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'
import { DateForm } from '../../components/Basicos'
import { formatLaravelDate, parseDate } from '../../components/Basicos/funcoes'

const FiltroGraficosInstituicao = ({ params, setParams }) => {
    const [data, setData] = useState({
        data_inicio: params.data_inicio,
        data_final: params.data_final
    })

    const onSubmit = (e) => {
        e.preventDefault()
        setParams({ ...data })
    }

    const onChange = (name, value) => {
        setData({
            data_inicio: value.from,
            data_final: value.to
        })
    }

    useEffect(() => {
        if (params.data_final !== undefined &&
            params.data_inicio !== undefined)
            setData(params)
    }, [params])

    const dateFormStyle = {
        "style": {
            "labelClass": "label-conteudoInferior",
            "elementoClass": "",
            "col": {
                "xs": 12,
                "sm": 12,
                "md": 12,
                "lg": 12,
                "xl": 12
            }
        }
    }

    return (
        <Col md={10} style={{ justifyContent: 'space-evenly' }}>
            <Row className="counter-card2">
                <Col sm={10}>
                    <Row>
                        <DateForm
                            showLabels={true}
                            labels={{ dtini: 'Data Inicial', dtfim: 'Data Final'}}
                            name="intervalo_data"
                            onChange={onChange}
                            dataInicial={{ "placeholder": "Data Inicio", ...dateFormStyle }}
                            dataFinal={{ "placeholder": "Data Fim", ...dateFormStyle }}
                            value={{
                                from: parseDate(data.data_inicio, formatLaravelDate),
                                to: parseDate(data.data_final, formatLaravelDate)
                            }}
                        />
                    </Row>
                </Col>
                <Col md={2} className={"field-button"}>
                    <button type="button" name="novo" onClick={onSubmit} className={"btn-consulta"}>Filtrar</button>
                </Col>
            </Row>
        </Col>
    )
}

export default FiltroGraficosInstituicao