import React, { Component } from 'react';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { axiosApi } from '../../services/axiosInstances';

import { Container, Row, Col } from 'reactstrap';
import logo from '../../img/logo.png';
import './styles.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { withRouter } from 'react-router-dom';
import { passwordAlteradoSucesso } from '../../components/Basicos/funcoes';

const TokenInvalido = () => {
    return (
        <Container fluid>
            <Row>
                <Col className="align-content-middle">
                    <img src={logo} alt="logo temporaria" width="160" />
                </Col>
            </Row>
            <Row className="align-content-middle">
                <Col>
                    <h1>Acesso Negado</h1>
                    <p>Seu token é invalido ou já expirou. Acesse o aplicativo e clique em:</p>
                    <p><strong>Esqueceu a Senha? </strong> e siga as instruções do e-mail</p>
                </Col>
            </Row>
        </Container>
    )
}

const Sucesso = () => {
    return (
        <Container fluid>
            <Row>
                <Col className="align-content-middle">
                    <img src={logo} alt="logo temporaria" width="160" />
                </Col>
            </Row>
            <Row className="align-content-middle">
                <Col>
                    <h1>Sua senha foi alterada com sucesso</h1>
                    <p>acesse o aplicativo, e entre com sua nova senha.</p>
                </Col>
            </Row>
        </Container>
    )
}

class PasswordReset extends Component {

    constructor(props) {
        super(props);
        this.passwordRef = React.createRef();
        this.iconRef = React.createRef();

        this.state = {
            isRevealPassword: false,
            email: "",
            password: "",
            tokenIsValid: false,
        };
    }

    togglePassword = event => {
        this.setState({
            isRevealPassword: !this.state.isRevealPassword
        });
    }

    componentDidMount = async () => {
        try {
            const { token } = this.props.match.params
            const tokenIsValid = await axiosApi.get(`password/${token}`);
            this.setState({ tokenIsValid: tokenIsValid.data.valid, email: tokenIsValid.data.dados.email });
        } catch (error) {
            console.log(error);
        }
    }

    handleSubmit = async (values) => {
        try {
            const response = await axiosApi.put(`password`, values);
            passwordAlteradoSucesso('Senha Alterada Com Sucesso!');
        } catch (error) {
            console.log(error);
        }
    };

    render() {
        const { isRevealPassword } = this.state;
        const validations = yup.object().shape({
            email: yup
                .string()
                .email('Email invalido')
                .required('Campo obrigatório'),
            password: yup
                .string()
                .min(8, 'Minimo de 8 caracteres')
                .required('Campo obrigatório'),
        });

        return (
            this.state.tokenIsValid ?
                <Container fluid height="100%">
                    <Row noGutters>
                        <Col lg="12" sm="12" xs="12" className="align-content-middle">
                            <Row>
                                <Formik
                                    initialValues={{
                                        email: this.state.email,
                                        password: '',
                                        token: this.props.match.params.token
                                    }}
                                    onSubmit={this.handleSubmit}
                                    validationSchema={validations}>
                                    {({ errors, touched }) => (
                                        <Form>
                                            <Col className="align-content-middle">
                                                <img src={logo} alt="logo temporaria" width="160" />
                                            </Col>
                                            <Col>
                                                <Field name="email"
                                                    className="Reset-Field"
                                                    placeholder="Seu e-mail"
                                                    required
                                                />
                                                {errors.email && touched.email ? (<div className="error-email-Reset">{errors.email}</div>) : null}
                                            </Col>
                                            <Col>
                                                <Field type={isRevealPassword ? "text" : "password"}
                                                    name="password"
                                                    className="Reset-Field"
                                                    placeholder="Nova Senha"
                                                    required
                                                />
                                                <span onClick={this.togglePassword} ref={this.iconRef}>
                                                    {isRevealPassword ?
                                                        <FontAwesomeIcon icon={faEye} className="customIconReset" /> :
                                                        <FontAwesomeIcon icon={faEyeSlash} className="customIconReset" />
                                                    }
                                                </span>
                                            </Col>
                                            {errors.password && touched.password && (<div
                                                className="error-password-Reset">
                                                {errors.password}
                                            </div>)
                                            }
                                            <Col className="align-content-middle">
                                                <button type="submit" className="Reset-btn align-content-middle" disabled={this.state.password_match}><strong>Salvar</strong></button>
                                            </Col>
                                        </Form>
                                    )}
                                </Formik>
                            </Row>
                        </Col>
                    </Row>
                </Container> :
                <TokenInvalido />
        );
    };
}

export default withRouter(PasswordReset);