import React, { useState, useEffect } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Form, Input, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { IoIosArrowBack } from 'react-icons/io';
import { axiosApi } from '../../../services/axiosInstances';
import { convertToSlug, delay, success, parseDate, formatLaravelDate } from '../../Basicos/funcoes';
import { DateForm } from '../../Basicos';
import classnames from 'classnames';
import './styles.css'
import Toggle from 'react-toggle'
import { FiTrash2, FiEdit } from 'react-icons/fi';
import { Fragment } from 'react';
import { ModalContextConsumer } from '../modalContext';
import FormGeral from './FormGeral'
import ModalUsuarioInstituicao from '../ModalUsuarioInstituicao';
import UsuarioItem from './UsuarioItem';


const ModalInstituicao = ({ nomeUrl, titulo, editarItem, instituicao, caminho, isOpen, setOpenModal, backdrop }) => {
    const [dadosInstituicao, setDadosInstituicao] = useState({
        codigo: undefined,
        sigla: undefined,
        nome: undefined,
        cep: undefined,
        logradouro: undefined,
        numero: undefined,
        bairro: undefined,
        complemento: undefined,
        cidade: undefined,
        estado: undefined,
        nome_contato: undefined,
        setor_contato: undefined,
        telefone_contato: undefined,
        whatsapp: undefined,
        email: undefined,
        status: true,
        users: []
    });
    const [tabIndex, setTabIndex] = useState(0); // 0 - Geral, 1 - Usuarios 
    const [modalUsuario, setModalUsuario] = useState({ modalId: undefined, isOpen: false, usuario: undefined });
    const [tituloModal, setTituloModal] = useState();

    const onSubmit = async () => {
        const url = editarItem ? `instituicao/${editarItem}` : 'instituicao';
        try {
            if (instituicao)
                await axiosApi.put(url, dadosInstituicao);
            else
                await axiosApi.post(url, dadosInstituicao);
            setOpenModal(false);
            success(instituicao ? 'Alterado com sucesso!' : 'Adicionado com sucesso!');
            await delay(3000);
            window.location.pathname = caminho;
        } catch (error) {
            console.log(error)
        }
    }

    const onChange = (event) => {
        const { name, value } = event.target;
        setDadosInstituicao({ ...dadosInstituicao, [name]: value })
    };

    useEffect(() => {
        if (isOpen && instituicao) {
            setDadosInstituicao(instituicao)
        }
    }, [isOpen]);

    const onClick = (event) => {
        const { name, id } = event.target;
        if (name === 'novo') {
            setTituloModal('Novo Usuário')
            setModalUsuario({ isOpen: true, modalId: undefined, usuario: undefined })
        } else {
            const usuario = dadosInstituicao.users.find(user => (user.id == id));
            setTituloModal('Editar Usuário')
            setModalUsuario({ isOpen: true, modalId: id, usuario: usuario })
        }
    }

    return (

        <Modal isOpen={isOpen} backdrop={backdrop} setOpenModal={() => setOpenModal(false)} size='lg'>
            <ModalUsuarioInstituicao
                titulo={tituloModal}
                dadosInstituicao={dadosInstituicao}
                setDadosInstituicao={setDadosInstituicao}
                instituicaoId={instituicao?.id}
                usuario={modalUsuario.usuario}
                isOpen={modalUsuario.isOpen}
                setOpenModal={() => setModalUsuario({ isOpen: false, modalId: undefined, usuario: undefined })}
                size='lg' />
            <ModalHeader className="Modal-header">
                <Row noGutters>
                    <Col>
                        <button onClick={() => setOpenModal(false)} className='Back-btn' name='novo'><IoIosArrowBack size="3rem" /></button>
                    </Col>
                    <Col md={8}>
                        <h3 className='Modal-titulo'>{titulo}</h3>
                    </Col>
                    <Col>
                        <button onClick={onSubmit}
                            className='Modal-btn align-content-middle'
                            name='salvar'
                            disabled={false}
                        >
                            {instituicao ? 'Atualizar' : 'Salvar'}
                        </button>
                    </Col>
                </Row>
                <Nav tabs id='instituicao-tabs'>

                    <NavItem>
                        <NavLink
                            className={classnames({ active: tabIndex === 0 })}
                            onClick={() => setTabIndex(0)}
                        >
                            Geral
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: tabIndex === 1 })}
                            onClick={() => setTabIndex(1)}
                        >
                            Usuários
                        </NavLink>
                    </NavItem>
                </Nav>
            </ModalHeader>
            <ModalBody>
                <TabContent activeTab={tabIndex}>

                    <TabPane tabId={0}>
                        <FormGeral
                            dadosInstituicao={dadosInstituicao}
                            onChange={onChange}
                        />
                    </TabPane>

                    <TabPane tabId={1}>

                        <Row className="py-2">
                            <Col sm="7">&nbsp;</Col>
                            <Col sm="5" align="right">
                                <button className="Novo-btn" name="novo" onClick={onClick}>Novo Usuário</button>
                            </Col>
                        </Row>

                        {dadosInstituicao.users.map(usuario =>
                            <UsuarioItem usuario={usuario} onClick={onClick} modalIsopen={modalUsuario.isOpen} />
                        )}

                    </TabPane>
                </TabContent>
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
        </Modal>
    )
}

export default ModalInstituicao;