import React, { useEffect, useState } from 'react'
import { Row, Col, Input } from 'reactstrap';
import { FiTrash2, FiEdit } from 'react-icons/fi';
import { ModalContextConsumer } from '../modalContext';

const UsuarioItem = ({ usuario, onClick, modalIsopen }) => {
    const [usuarioLocal, setUsuarioLocal] = useState({
        nome: undefined,
        cargo: undefined,
        id: undefined
    })

    useEffect(() => {
        setUsuarioLocal({
            ...usuario
        })
    }, [modalIsopen])
    return (
        <Row className="py-2">
            <Col sm="5">
                <Input disabled
                    type='text'
                    name='nome'
                    placeholder={usuarioLocal.nome}
                    defaultValue={usuarioLocal.nome}
                />
            </Col>
            <Col sm="5">
                <Input disabled
                    type='text'
                    name='nome'
                    placeholder={usuarioLocal.cargo}
                    defaultValue={usuarioLocal.cargo}
                />
            </Col>
            <Col >
                <ModalContextConsumer>
                    {context => (
                        <button className="Categorias-btn align-content-middle" name="delete"
                            onClick={(e) => {
                                e.preventDefault();
                                context.setItem({ contexto: 'destroyusuarioinstituicao', id: usuarioLocal.id });
                                context.toggleModal();
                            }}>
                            <FiTrash2 className='Svg-event-none' />
                        </button>
                    )}
                </ModalContextConsumer>
                <button name="edit" id={usuarioLocal.id} className="Categorias-btn align-content-middle" onClick={onClick}><FiEdit className='Svg-event-none' /> </button>
            </Col>
        </Row>
    )
}

export default UsuarioItem