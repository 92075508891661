import React, { Component, Fragment, useState } from 'react';
import { axiosApi } from '../../services/axiosInstances';
import { Row, Col } from 'reactstrap';
import { Header, ModalEdit } from '../../components/Funcionalidades';
import { ModalContextConsumer } from '../../components/Funcionalidades/modalContext';

import { FiTrash2, FiEdit } from 'react-icons/fi';
import { logoutWhenTokenExpire } from '../../components/Basicos/funcoes';

import './styles.css';

const SubcategoriasDB = (props) => {
    return (
        <Col md={10} className="Subcategorias" value={props.id}>
            {props.descricao}
            <ModalContextConsumer>
                {context => (
                    <button className="Subcategorias-btn" name="delete"
                        onClick={(e) => {
                            e.preventDefault();
                            context.setItem({ contexto: 'subcategorias', id: props.id });
                            context.toggleModal();
                        }}>
                        <FiTrash2 className='Svg-event-none' />
                    </button>
                )}
            </ModalContextConsumer>
            <button className="Subcategorias-btn" id={props.id} name="edit" onClick={props.onClick}><FiEdit className='Svg-event-none' /></button>
        </Col>
    )
}

export default class Subcategorias extends Component {

    constructor(props) {
        super(props);

        this.state = {
            subcategorias: [],
            openModal: false,
            idModal: ''
        };
    }

    handleClick = (event) => {
        this.setState({ openModal: true })
        this.setState({ idModal: event.target.id })
    }

    componentDidMount() {
        this.loadSubcategorias();
    };

    loadSubcategorias = async () => {
        try {
            const response = await axiosApi.get('subcategorias');
            this.setState({ subcategorias: response.data });
        } catch (error) {
            console.log(error);
            logoutWhenTokenExpire();
        }
    };

    render() {
        return (
            <Fragment>
                <Header />
                <ModalEdit titulo={'Editar Subcategoria'} isOpen={this.state.openModal} backdrop={'static'} nome={'Subcategorias'} setOpenModal={() => { this.setState({ openModal: false }) }} editarItem={this.state.idModal} caminho={window.location.pathname} subcategoria={true} />
                <Row>
                    {this.state.subcategorias.map(subcategoria => (
                        <SubcategoriasDB
                            key={subcategoria.id}
                            id={subcategoria.id}
                            descricao={subcategoria.descricao}
                            onClick={this.handleClick}
                        />
                    ))}
                </Row>
            </Fragment>
        )

    }
}