import React, { useState, useEffect } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Form, Input } from 'reactstrap';
import { IoIosArrowBack } from 'react-icons/io';
import { axiosApi } from '../../../services/axiosInstances';
import { convertToSlug, delay, success, parseDate, formatLaravelDate } from '../../Basicos/funcoes';
import { DateForm } from '../../Basicos';
import Toggle from 'react-toggle'


const ModalUsuarioInstituicao = ({ instituicaoId, titulo, isOpen, setOpenModal, backdrop, usuario, dadosInstituicao, setDadosInstituicao }) => {
    const usuarioAttributes = {
        id: '_' + Math.random().toString(36).substr(2, 9),
        nome: undefined,
        cargo: undefined,
        email: undefined,
        password: undefined,
        telefone: undefined,
        whatsapp: undefined,
        instituicao_id: instituicaoId,
        status: false
    }
    const [usuarioInstituicao, setUsuarioInstituicao] = useState({ ...usuarioAttributes });

    const adicionarUsuario = async () => {
        const instituicaoPrev = { ...dadosInstituicao }
        console.log(usuarioInstituicao);
        if (instituicaoId) {
            await axiosApi.post('usuario-instituicao', usuarioInstituicao);
        }
        instituicaoPrev.users.push(usuarioInstituicao);
        setDadosInstituicao(instituicaoPrev);
        closeModalAndClearFields();
    }

    const onChange = (event) => {
        const { name, value } = event.target;
        setUsuarioInstituicao({ ...usuarioInstituicao, [name]: value })
    };

    const closeModalAndClearFields = () => {
        setOpenModal();
        setUsuarioInstituicao({ ...usuarioAttributes });
    }

    useEffect(() => {
        if (isOpen && usuario) {
            setUsuarioInstituicao({ ...usuario })
        }
    }, [isOpen]);

    return (

        <Modal isOpen={isOpen} backdrop={backdrop} setOpenModal={closeModalAndClearFields} size='lg'>
            <ModalHeader className="Modal-header">
                <Row noGutters>
                    <Col>
                        <button onClick={closeModalAndClearFields} className='Back-btn' name='cancelar'><IoIosArrowBack size="3rem" /></button>
                    </Col>
                    <Col md={8}>
                        <h3 className='Modal-titulo'>{titulo}</h3>
                    </Col>
                    <Col>
                        <button onClick={adicionarUsuario}
                            className='Modal-btn align-content-middle'
                            name='salvar'
                            disabled={false}
                        >
                            {usuario ? 'Atualizar' : 'Salvar'}
                        </button>
                    </Col>
                </Row>
            </ModalHeader>
            <ModalBody>

                <Form>
                    <Row className="py-2">
                        <Col sm="6">
                            <Input
                                type='text'
                                name='nome'
                                placeholder={'Nome'}
                                onChange={onChange}
                                defaultValue={usuarioInstituicao?.nome}
                                valid={usuarioInstituicao?.nome !== undefined}
                                invalid={usuarioInstituicao?.nome === undefined || usuarioInstituicao?.nome === ''}
                            />
                        </Col>
                        <Col sm="6">
                            <Input
                                type='text'
                                name='cargo'
                                placeholder={'Cargo'}
                                onChange={onChange}
                                defaultValue={usuarioInstituicao?.cargo}
                                valid={usuarioInstituicao?.cargo !== undefined}
                                invalid={usuarioInstituicao?.cargo === undefined || usuarioInstituicao?.cargo === ''}
                            />
                        </Col>
                    </Row>
                    <Row className="py-2">
                        <Col sm="6">
                            <Input
                                type='text'
                                name='email'
                                placeholder={'E-mail'}
                                onChange={onChange}
                                defaultValue={usuarioInstituicao?.email}
                                valid={usuarioInstituicao?.email !== undefined}
                                invalid={usuarioInstituicao?.email === undefined || usuarioInstituicao?.email === ''}
                            />
                        </Col>
                        <Col sm="6">
                            <Input
                                type='password'
                                name='password'
                                placeholder={'Senha'}
                                onChange={onChange}
                                defaultValue={usuarioInstituicao?.password}
                                valid={usuarioInstituicao?.password !== undefined}
                                invalid={usuarioInstituicao?.password === undefined || usuarioInstituicao?.password === ''}
                            />
                        </Col>
                    </Row>
                    <Row className="py-2">
                        <Col sm="6">
                            <Input
                                type='text'
                                name='telefone'
                                placeholder={'Telefone Contato'}
                                onChange={onChange}
                                defaultValue={usuarioInstituicao?.telefone}
                                valid={usuarioInstituicao?.telefone !== undefined}
                                invalid={usuarioInstituicao?.telefone === undefined || usuarioInstituicao?.telefone === ''}
                            />
                        </Col>
                        <Col sm="6">
                            <Input
                                type='text'
                                name='whatsapp'
                                placeholder={'Whatsapp'}
                                onChange={onChange}
                                defaultValue={usuarioInstituicao?.whatsapp}
                                valid={usuarioInstituicao?.whatsapp !== undefined}
                                invalid={usuarioInstituicao?.whatsapp === undefined || usuarioInstituicao?.whatsapp === ''}
                            />
                        </Col>
                    </Row>
                    <Row className="py-2">

                        <Col sm="12" Align="Right">
                            STATUS
                            <label>
                                <Toggle
                                    defaultChecked={usuarioInstituicao.status}
                                    name='status'
                                    onChange={onChange}
                                />
                            </label>

                        </Col>
                    </Row>
                </Form>
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
        </Modal>
    )
}

export default ModalUsuarioInstituicao;