import React, { useState, useEffect } from 'react';
import Main from './pages/Main';
import Login from './pages/Login';
import { BrowserRouter as Router, Redirect, Switch, Route } from "react-router-dom";

import { ativarRefreshToken } from './services/axiosInstances';
import Logout from './pages/Logout';

import './styles.css';
import PasswordReset from './pages/PasswordReset';

function RotasNaoAutenticada({ token, setToken }) {
    return (
        <Switch>
            <Route path={'/login'} >
                <Login token={token} setToken={setToken} />
            </Route>
            <Route path={'/password/:token'} >
                <PasswordReset />
            </Route>
            <Route >
                <Redirect to="/login" />
            </Route>
        </Switch>
    )
}

function RotasAutenticada({ token, setToken }) {
    return (
        <Switch>
            <Route path={'/'} >
                <Main />
            </Route>
            <Route path="/auth/logout" >
                <Logout setToken={setToken} />
            </Route>
            <Route >
                <Redirect to="/" />
            </Route>
        </Switch >
    )
}

function App() {
    const [token, setToken] = useState(localStorage.getItem('token-orto'));

    useEffect(() => {
        if (token) {
            ativarRefreshToken(setToken)
        }
    }, []);

    return (
        <Router basename="/quizmed">
            {token ? <RotasAutenticada token={token} setToken={setToken} /> : <RotasNaoAutenticada token={token} setToken={setToken} />}
        </Router>
    );
}

export default App;
