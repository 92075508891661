import React, { Component, Fragment } from 'react';
import { axiosApi } from '../../services/axiosInstances';
import { Header } from '../../components/Funcionalidades';
import { Row, Nav } from 'reactstrap';
import { PreEditionListItemCard } from './../../components/Basicos';
import { logoutWhenTokenExpire } from '../../components/Basicos/funcoes';

export default class Perguntas extends Component {

    constructor(props) {
        super(props);
        this.state = {
            categorias: []
        };
    }

    componentDidMount() {
        this.loadCategorias();
    };

    loadCategorias = async () => {
        try {
            const response = await axiosApi.get('categorias');
            this.setState({
                categorias: response.data
            });
        } catch (error) {
            console.log(error);
            logoutWhenTokenExpire();
        }
    };

    render() {
        return (
            <Fragment>
                <Header />
                <Row noGutters>
                    <Nav>
                        {this.state.categorias.map(categoria => (
                            <PreEditionListItemCard key={categoria.id}
                                icon={categoria.image[0].url}
                                descricao={categoria.descricao}
                                hash={`#${categoria.id}`}
                                pathname="/perguntas/listagem"
                            />
                        ))}
                        <PreEditionListItemCard
                            icon={true}
                            descricao={"Desafios"}
                            hash={`#desafios`}
                            pathname="/perguntas/listagem"
                        />
                    </Nav>
                </Row>
            </Fragment >
        )
    }
}
