import React, { Component } from 'react';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';

import { axiosApi, ativarRefreshToken } from '../../services/axiosInstances';

import { Container, Row, Col } from 'reactstrap';
import quizNome from '../../img/img_bg_login.png';
import logo from '../../img/logo.png';
import './styles.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

class Login extends Component {

    constructor(props) {
        super(props);
        this.passwordRef = React.createRef();
        this.iconRef = React.createRef();

        this.state = {
            isRevealPassword: false,
            email: "",
            password: ""
        };
    }

    onChange = (event) => {
        const { name, value } = event.target.value;
        this.setState({ [name]: value });
    }


    togglePassword = event => {
        this.setState({
            isRevealPassword: !this.state.isRevealPassword
        });
    }

    handleSubmit = async (values) => {
        try {
            const response = await axiosApi.post('auth/login', values);
            const { access_token, expires_in, instituicao, instituicao_id } = response.data;
            localStorage.setItem('token-orto', access_token);
            localStorage.setItem('expires-in-orto', expires_in);
            localStorage.setItem('instituicao', instituicao);
            localStorage.setItem('instituicao_id', instituicao_id);
            axiosApi.defaults.headers.common['authorization'] = `bearer ${access_token}`;
            this.props.setToken(access_token);
            ativarRefreshToken(this.props.setToken);
        } catch (error) {
            console.log(error)
        }
    };

    render() {
        const { isRevealPassword } = this.state;

        const validations = yup.object().shape({
            email: yup
                .string()
                .email('Email invalido')
                .required('Campo obrigatório'),
            password: yup
                .string()
                .min(8, 'Minimo de 8 caracteres')
                .required('Campo obrigatório')
        });

        return (
            <Container fluid height="100%">
                <Row>
                    <Col lg="4" className="img-lateral align-content-middle">
                        <img src={quizNome} alt="lateral temporaria" />
                    </Col>
                    <Col lg="8" className="align-content-middle">
                        <Row>
                            <Formik
                                initialValues={{
                                    email: '',
                                    password: '',
                                }}
                                onSubmit={this.handleSubmit}
                                validationSchema={validations}>
                                {({ errors, touched }) => (
                                    <Form>
                                        <Col className="align-content-middle">
                                            <img src={logo} alt="logo temporaria" width="160" />
                                        </Col>
                                        <Col>
                                            <Field name="email"
                                                className="Login-Field"
                                                placeholder="Seu e-mail"
                                                required
                                            />
                                            {errors.email && touched.email ? (<div className="error-email">{errors.email}</div>) : null}
                                        </Col>

                                        <Col className="align-content-middle">
                                            <Field type={isRevealPassword ? "text" : "password"}
                                                name="password"
                                                className="Login-Field"
                                                placeholder="Sua senha"
                                                required
                                            />
                                            <span onClick={this.togglePassword} ref={this.iconRef}>

                                                {isRevealPassword ?
                                                    <FontAwesomeIcon icon={faEye} className="customIcon" /> :
                                                    <FontAwesomeIcon icon={faEyeSlash} className="customIcon" />
                                                }
                                            </span>
                                        </Col>
                                        {errors.password && touched.password ? (<div
                                            className="error-password">
                                            {errors.password}
                                        </div>)
                                            : null}
                                        <Col className="align-content-middle">
                                            <button type="submit" className="Login-btn align-content-middle"><strong>Entrar</strong></button>
                                        </Col>
                                    </Form>
                                )}
                            </Formik>
                        </Row>
                    </Col>
                </Row>
            </Container>
        );
    };
}

export default Login;