import React, { Component, Fragment, useState } from 'react';
import { axiosApi } from '../../services/axiosInstances';
import { Row, Col } from 'reactstrap';
import { FiTrash2, FiEdit } from 'react-icons/fi';
import { Header, ModalEdit } from '../../components/Funcionalidades';
import { logoutWhenTokenExpire } from '../../components/Basicos/funcoes';
import { ModalContextConsumer } from '../../components/Funcionalidades/modalContext';

import './styles.css';

const CategoriasDB = (props) => {
    return (
        <Col md={10} className="Categorias" value={props.id}>
            {props.descricao}
            <ModalContextConsumer>
                {context => (
                    <button className="Categorias-btn align-content-middle" name="delete"
                        onClick={(e) => {
                            e.preventDefault();
                            context.setItem({ contexto: 'categorias', id: props.id });
                            context.toggleModal();
                        }}>
                        <FiTrash2 className='Svg-event-none' />
                    </button>
                )}
            </ModalContextConsumer>
            <button name="edit" id={props.id} className="Categorias-btn align-content-middle" onClick={props.onClick}><FiEdit className='Svg-event-none' /> </button>
        </Col>
    )
}

export default class Categorias extends Component {

    constructor(props) {
        super(props);

        this.state = {
            categorias: [],
            openModal: false,
            idModal: ''
        };
    }

    handleClick = (event) => {
        this.setState({ openModal: true })
        this.setState({ idModal: event.target.id })
    }

    componentDidMount() {
        this.loadCategorias();
    };

    loadCategorias = async () => {
        try {
            const response = await axiosApi.get('categorias');
            this.setState({ categorias: response.data });
        } catch (error) {
            console.log(error);
            logoutWhenTokenExpire();
        }
    };

    render() {
        return (
            <Fragment>
                <Header />
                <ModalEdit titulo={'Editar Categoria'}
                          isOpen={this.state.openModal}
                          backdrop={'static'}
                          nome={'Categorias'}
                          setOpenModal={() => { this.setState({ openModal: false }) }} 
                          editarItem={this.state.idModal}
                          caminho={window.location.pathname} />
                <Row className="conteudo-tamanho">
                    {this.state.categorias.map(categoria => (
                        <CategoriasDB
                            key={categoria.id}
                            descricao={categoria.descricao}
                            id={categoria.id}
                            onClick={this.handleClick}
                        />
                    ))}
                </Row>
            </Fragment>
        )

    }
}