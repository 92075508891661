import React, { Component, Fragment } from 'react';
import { axiosApi } from '../../services/axiosInstances';
import { Row, Col, CustomInput } from 'reactstrap';
import { Header } from '../../components/Funcionalidades';
import { DateForm, SelectForm, NewLoader } from '../../components/Basicos';
import { formatLaravelDate, parseDate, jsonToFormData, logoutWhenTokenExpire, success, isNull } from '../../components/Basicos/funcoes';
import FileExportForm from '../../components/Basicos/Form/FileExportForm';

export default class EditarNoticia extends Component {

    constructor(props) {
        super(props);

        this.state = {
            tipoNoticia: '',
            tipoNoticiaOpcoes: [],
            initialValues: {
                titulo: '',
                subtitulo: '',
                tiponoticia_id: '',
                data_inicial: '',
                data_final: '',
                video_url: '',
                imagem: [],
                isImage: true,
            },
            imgpadrao: [],
            nova_padrao: false,
            nova_imagem: false,
            isLoading: true
        };
    }

    componentDidMount() {
        const loadAll = async () => {
            try {
                await Promise.all([this.loadTiposNews(), this.loadNoticia()]);
                this.setState({isLoading: false});
            } catch (error) {
                console.log(error);
                logoutWhenTokenExpire();
            }
        };

        loadAll();
    };
    loadTiposNews = async () => {
        const response = await axiosApi.get('noticias?tiposnoticia=true');
        this.setState({ tipoNoticia: response.data });
        this.state.tipoNoticia.map(tipo => (
            this.setState({
                tipoNoticiaOpcoes: this.state.tipoNoticiaOpcoes.concat({
                    value: tipo.id,
                    label: tipo.descricao
                })
            })
        ))
    };
    loadNoticia = async () => {
        try {
            const id = this.props.location.hash.substr(1);
            const response = await axiosApi.get(`noticias/${id}`);
            this.setState({
                initialValues: response.data,
                imgpadrao: response.data.img_padrao
            });
        } catch (error) {
            console.log(error)
        }
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const id = this.props.location.hash.substr(1);
            const [imagem] = this.state.initialValues.imagem;
            const [imgpadrao] = this.state.imgpadrao;
            const atualizarNoticia = {
                user_id: 1,
                titulo: this.state.initialValues.titulo,
                subtitulo: this.state.initialValues.subtitulo,
                tiponoticia_id: this.state.initialValues.tiponoticia_id,
                data_inicial: this.state.initialValues.data_inicial,
                data_final: this.state.initialValues.data_final,
                video_url: !this.state.initialValues.isImage ? this.state.initialValues.video_url : undefined,
                imagem: this.state.initialValues.isImage ? imagem : [],
                imgpadrao: imgpadrao,
                nova_padrao: this.state.nova_padrao,
                nova_imagem: this.state.nova_imagem
            }

            await axiosApi.post(`noticias/${id}`, jsonToFormData(atualizarNoticia, 'PUT'), {
                headers: { "Content-Type": "multipart/form-data;" }
            });
            success('Alterado com Sucesso!');
        } catch (error) {
            console.log(error);
        }
        window.history.back();
    };
    selectOnchange = (nome, valor) => {
        const initialValues = { ...this.state.initialValues };
        initialValues[nome] = valor;

        this.setState({ initialValues });
    }
    onChange = (event) => {
        const { name, value } = event.target

        this.setState({
            initialValues: {
                ...this.state.initialValues,
                [name]: value
            }
        })
    }
    onChangeHandler = (nome, valor) => {
        switch (nome) {
            case 'imgpadrao':
                this.setState({
                    [nome]: valor,
                    nova_padrao: true
                })
                break;
            case 'imagem':
                this.setState({
                    initialValues: {
                        ...this.state.initialValues,
                        [nome]: valor
                    },
                    nova_imagem: true
                })
                break;
            case 'intervalo_data':
                this.setState({
                    initialValues: {
                        ...this.state.initialValues,
                        data_inicial: valor.from,
                        data_final: valor.to,
                    }
                });
                break;
        }
    }

    toggle = () => {
        const prevState = { ...this.state.initialValues };
        prevState.isImage = !prevState.isImage;
        this.setState({ initialValues: prevState });
    };

    render() {
        return (
            <Fragment>
                <Header />
                <NewLoader overlay isActive={this.state.isLoading}>
                    <Row className="conteudo-tamanho align-content-middle">
                        <form className="form-pergunta" onSubmit={this.handleSubmit}>
                            <Col>
                                <input
                                    name="titulo"
                                    onChange={this.onChange}
                                    className="Pergunta-Field"
                                    placeholder="Título"
                                    value={this.state.initialValues.titulo} />
                            </Col>
                            <Col>
                                <textarea
                                    row="2"
                                    name="subtitulo"
                                    onChange={this.onChange}
                                    className="Pergunta-Field"
                                    placeholder="Corpo da Notícia"
                                    value={this.state.initialValues.subtitulo} />
                            </Col>
                            <Col>
                                <Row className='Pergunta-select-row'>
                                    <Col md={4}>
                                        <SelectForm
                                            name={"tiponoticia_id"}
                                            options={this.state.tipoNoticiaOpcoes}
                                            onChange={this.selectOnchange}
                                            placeholder={"Tipo Notícia"}
                                            className={"Pergunta-Select"}
                                            value={this.state.tipoNoticiaOpcoes.find(elemento => (elemento.value === this.state.initialValues.tiponoticia_id))}
                                        />
                                    </Col>
                                    <Col md={8}>
                                        <Row className='picker-row'>
                                            <DateForm
                                                name="intervalo_data"
                                                onChange={this.onChangeHandler}
                                                dataInicial={{ "placeholder": "Data Postagem", ...dateFormStyle }}
                                                dataFinal={{ "placeholder": "Data Exclusão", ...dateFormStyle }}
                                                value={{
                                                    from: parseDate(this.state.initialValues.data_inicial, formatLaravelDate),
                                                    to: parseDate(this.state.initialValues.data_final, formatLaravelDate)
                                                }}
                                            />
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <FileExportForm
                                    onChange={this.onChangeHandler}
                                    valorAtual={this.state.imgpadrao}
                                    name={"imgpadrao"}
                                    limiteDeArquivos={1}
                                    subscreverImagens
                                    required
                                />
                            </Col>
                            <Col>
                                <CustomInput onClick={this.toggle} className="align-content-middle" type="switch" id="switchImageVideo" name="switchImageVideo" label="Imagem ou Vídeo" checked={!this.state.initialValues.isImage} />
                                {
                                    !this.state.initialValues.isImage ?
                                        <input
                                            name="video_url"
                                            onChange={this.onChange}
                                            className="Pergunta-Field"
                                            value={this.state.initialValues.video_url}
                                            placeholder="Link do Youtube"
                                        />
                                        :
                                        <FileExportForm
                                            onChange={this.onChangeHandler}
                                            valorAtual={this.state.initialValues.imagem}
                                            name={"imagem"}
                                            limiteDeArquivos={1}
                                            subscreverImagens
                                        />
                                }

                            </Col>
                            <Col className="align-content-middle">
                                <button type="submit" className="Login-btn align-content-middle" >Atualizar</button>
                            </Col>
                        </form>
                    </Row>
                </NewLoader>
            </Fragment>
        );
    };
}

const dateFormStyle = {
    "style": {
        "labelClass": "label-conteudoInferior",
        "elementoClass": "",
        "col": {
            "xs": 12,
            "sm": 12,
            "md": 12,
            "lg": 12,
            "xl": 12
        }
    }
}