import React, { Fragment, useEffect, useState } from 'react';
import { NavItem, Nav, Row } from 'reactstrap'
import { NavLink } from 'react-router-dom'
import { FaQuestion, FaRegUser, FaRegHandshake, FaSlidersH, FaSearch } from 'react-icons/fa';//icones
import { AiOutlineHome } from 'react-icons/ai';//icones
import { RiNewspaperLine } from 'react-icons/ri';//icones
import { FiSettings, FiLogOut } from 'react-icons/fi';//icones
import logo from '../../../img/logo.png';//logo
import './styles.css'

function MenuAdmin() {
    return <Nav className='flex-column'>
        <NavItem>
            <NavLink activeClassName='is-active' exact to={'/'}>
                <AiOutlineHome />
                <span>Home</span>
            </NavLink>
        </NavItem>
        <NavItem>
            <NavLink activeClassName='is-active' to={'/perguntas'} >
                <FaQuestion />
                <span>Perguntas</span>
            </NavLink>
        </NavItem>
        <NavItem>
            <NavLink activeClassName='is-active' to={'/noticias'} >
                <RiNewspaperLine />
                <span>Noticias</span>
            </NavLink>
        </NavItem>
        <NavItem>
            <NavLink activeClassName='is-active' to={'/usuarios'} >
                <FaRegUser />
                <span>Usuários</span>
            </NavLink>
        </NavItem>
        <NavItem>
            <NavLink activeClassName='is-active' to={'/consultas'} >
                <FaSearch />
                <span>Consultas</span>
            </NavLink>
        </NavItem>
        <NavItem>
            <NavLink activeClassName='is-active' to={'/apoiadores'} >
                <FaRegHandshake />
                <span>Patrocinadores</span>
            </NavLink>
        </NavItem>
        <NavItem>
            <NavLink activeClassName='is-active' to={'/auxiliares'}>
                <FaSlidersH />
                <span>Auxiliares</span>
            </NavLink>
        </NavItem>
        <NavItem>
            <NavLink exact to={'/auth/logout'}>
                <FiLogOut />
                <span>Sair</span>
            </NavLink>
        </NavItem>
    </Nav>
}

function MenuInstituicao() {
    return <Nav className='flex-column'>
        <NavItem>
            <NavLink activeClassName='is-active' exact to={'/graficos'}>
                <AiOutlineHome />
                <span>Home</span>
            </NavLink>
        </NavItem>
        <NavItem>
            <NavLink exact to={'/auth/logout'}>
                <FiLogOut />
                <span>Sair</span>
            </NavLink>
        </NavItem>
    </Nav>
}

function Sidebar() {

    const [isInstituicao, setIsInstituicao] = useState(false)

    useEffect(() => {
        const instituicao = localStorage.getItem('instituicao')
        console.log(atob(instituicao))
        if (atob(instituicao) == 3) {
            setIsInstituicao(true)
        }
    }, [])

    return (
        <Fragment>
            <Row className='align-content-middle'>
                <img src={logo} width='180' height='auto' alt='logo quiz ortopedia'></img>
            </Row>
            {
                isInstituicao ?
                    <MenuInstituicao /> :
                    <MenuAdmin />
            }
        </Fragment>
    );
}

export default Sidebar;