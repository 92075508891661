import { id } from 'date-fns/locale';
import React from 'react'
import { useState } from 'react';
import { Col, Form, Input, Row, Button } from 'reactstrap';
import { SelectForm } from '../../Basicos';
import { FiTrash2, FiPlus } from 'react-icons/fi';
import { useEffect } from 'react';

const FuncionalidadesForm = ({ dados, setDados, funcionalidades, editarItem }) => {
    const [dadosForm, setDadosForm] = useState([{
        componentId: '_' + Math.random().toString(36).substr(2, 9)
    }]);

    useEffect(() => {
        if (dados.planos_funcionalidades.length != 0) {
            const planosFuncionalidades = dados.planos_funcionalidades.map(el => ({ componentId: el.id, ...el }));
            setDadosForm(planosFuncionalidades);
        }
    }, [dados])

    const addItemForm = (e) => {
        e.preventDefault();
        const fakeId = '_' + Math.random().toString(36).substr(2, 9),
            prevQuantidade = dadosForm.slice()
        prevQuantidade.push({ componentId: fakeId })

        setDadosForm(prevQuantidade)
    }

    const removeItemForm = (componentId) => {
        if (dadosForm.length > 1) {
            const dadosFiltrados = dadosForm.filter(el => el.componentId != componentId)
            setDadosForm(dadosFiltrados)
            setDados({ ...dados, planos_funcionalidades: dadosFiltrados })
        }
        
    }

    const onChange = (name, value, componentId) => {
        const dadosFormPrev = dadosForm.slice();
        const formIndex = dadosForm.findIndex(el => el.componentId == componentId)
        dadosFormPrev[formIndex] = {
            ...dadosFormPrev[formIndex],
            [name]: value
        }
        setDadosForm(dadosFormPrev)
        setDados({ ...dados, planos_funcionalidades: dadosFormPrev })
    }

    return <Form>
        {dadosForm.map((el) =>
        (<Row key={el.componentId} className='align-content-middle'>
            <Col className="col-6">
                <SelectForm
                    indice={el.componentId}
                    name={"funcionalidade_id"}
                    options={funcionalidades} //puxhar as opcoes do banco
                    value={funcionalidades && funcionalidades.find(elemento => (elemento.value === el.funcionalidade_id))}
                    onChange={onChange}
                    menuPortalTarget={document.body}
                    placeholder={"Seleciona a Funcionalidade"}
                    className={"select-curso-usuario"}
                />
            </Col>
            <Col className="col-4">
                <Input
                    id={el.componentId}
                    type='text'
                    name='valor_funcionalidade'
                    placeholder={"Quant."}
                    onChange={(e) => onChange(e.target.name, e.target.value, e.target.id)}
                    value={el.valor_funcionalidade}
                />
            </Col>
            <Col className="col-auto">
                <button name="remove"
                    className="Categorias-btn align-content-middle"
                    onClick={(e) => {
                        e.preventDefault()
                        removeItemForm(el.componentId)
                    }
                    }>
                    <FiTrash2 size={50} className='feather' />
                </button>
                <button name="add"
                    className="Categorias-btn align-content-middle"
                    onClick={addItemForm}>
                    <FiPlus size={50} className='feather' />
                </button>
            </Col>
        </Row>
        )
        )}


    </Form>

}

export default FuncionalidadesForm