import React, { Fragment, useState, useEffect } from 'react'
import { Col, Row } from 'reactstrap';
import { Header } from '../../components/Funcionalidades';
import { axiosApi } from '../../services/axiosInstances';
import { FiTrash2, FiEdit } from 'react-icons/fi';
import { ModalContextConsumer } from '../../components/Funcionalidades/modalContext';
import ModalCurso from '../../components/Funcionalidades/ModalCurso';

const Cursos = () => {

    const [cursos, setCursos] = useState();
    const [modal, setModal] = useState({
        openModal: false,
        idModal: ''
    });

    const loadCursos = async () => {
        const response = await axiosApi.get('cursos');
        setCursos(response.data.cursos);
    }

    useEffect(() => {
        loadCursos();
    }, [])

    const handleClick = (event) => {
        setModal({
            ...modal,
            openModal: !modal.openModal,
            idModal: event.target.id
        })
    }

    const CursosDB = ({ nome, onClick, id, disabled }) => {
        const master = id === 1
        return (
            <Col md={10} className={`Categorias ${disabled ? 'categoria-disabled' : ''}`} value={id}>
                {nome}
                {
                    !master &&
                    <Fragment>
                        <ModalContextConsumer>
                            {context => (
                                <button className="Categorias-btn align-content-middle" name="delete"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        context.setItem({ contexto: 'cursos', id: id });
                                        context.toggleModal();
                                    }}>
                                    <FiTrash2 className='Svg-event-none' />

                                </button>
                            )}
                        </ModalContextConsumer>
                        <button name="edit" id={id} className="Categorias-btn align-content-middle" onClick={onClick}><FiEdit className='Svg-event-none' /> </button>
                    </Fragment>
                }

            </Col>
        )
    }

    return (
        <Fragment>
            <Header />
            <ModalCurso titulo={'Editar Curso'}
                isOpen={modal.openModal}
                backdrop={'static'}
                nomeUrl={'Cursos'}
                curso={cursos && cursos.filter(curso => curso.id == modal.idModal)}
                setOpenModal={() => { setModal({ ...modal, openModal: false }) }}
                editarItem={modal.idModal}
                caminho={window.location.pathname}
            />
            <Row className="conteudo-tamanho">
                {cursos && cursos.map(curso => (
                    <CursosDB
                        key={curso.id}
                        nome={curso.nome}
                        id={curso.id}
                        onClick={handleClick}
                        disabled={!curso.status}
                    />
                ))}
            </Row>
        </Fragment>
    )
}

export default Cursos;