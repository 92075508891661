import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormFeedback, FormGroup } from "reactstrap"
import { selectStyle } from '../funcoes'
import Select from 'react-select'

/********************************************************************************************************************************************/
//Componente responsavel por configurar um select do react-select de acordo com a necessidade de gerar formulario dinamicos para supervisor//
/******************************************************************************************************************************************/

const SelectForm = (props) => {
    const [manipuladoPeloUsuario, setManipuladoPeloUsuario] = useState(false);
    const [maximoSelecionado, setMaximoSelecionado] = useState(false);

    const onChange = (selecionados, { action }) => {
        if (props.isMulti) {
            selecionados = selecionados ? selecionados : [];
            const valores = selecionados.length >= 1 ? selecionados.map((elemento) => (elemento.value)) : undefined;

            if (action === "select-option" && maximoSelecionado) {
                return;
            }

            if (action === "select-option" && selecionados.length === props.maxOptions) {
                setMaximoSelecionado(true);
            }
            setMaximoSelecionado(props.maxOptions ? selecionados.length >= props.maxOptions : false);
            props.onChange(props.name, valores, props.indice);
        } else {
            props.onChange(props.name, selecionados.value, props.indice);
        }
    }

    const noOptionsMessage = () => (maximoSelecionado ? 'Máximo atingido' : 'Sem opções');

    const changeClass = () => (isValid() ? "is-invalid input-invalid" : "");

    const isValid = () => ((manipuladoPeloUsuario || props.submit) && props.invalido);

    return (
        <div className={"div-wrapper " + (props.hidden ? "hidden" : "")}>
            {/* <Label className={props.style.labelClass + " label"}>
                    {props.label + ":"}
                </Label> */}
            <FormGroup className="form-group-feedback-input">
                <Select
                    defaultValue={props.valorPadrao}
                    noOptionsMessage={noOptionsMessage}
                    isMulti={props.isMulti}
                    placeholder={props.placeholder}
                    options={maximoSelecionado ? undefined : props.options}
                    styles={selectStyle}
                    onBlur={() => setManipuladoPeloUsuario(true)}
                    className={"select select-form " + props.style.elementoClass + changeClass() + " " + props.className}
                    onChange={onChange}
                    isDisabled={props.readOnly}
                    menuPortalTarget={props.menuPortalTarget}
                    value={props.value}
                    menuContainerStyle={{ 'zIndex': 9999 }}
                />
                {!props.disabled && (
                    <input
                        tabIndex={-1}
                        autoComplete="off"
                        value={props.value}
                        required={props.required}
                        style={{
                            opacity: 0,
                            width: "100%",
                            height: 0,
                            position: "absolute",
                          }}
                    />
                )}
                <FormFeedback style={{ display: isValid() ? "block" : "none" }}>
                    Selecione uma opção valida !
                </FormFeedback>
            </FormGroup>
        </div>
    );
}

export default React.memo(SelectForm);



const colShape = {
    xs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    md: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    lg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    xl: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

const styleShape = {
    labelClass: PropTypes.string,
    elementoClass: PropTypes.string,
    col: PropTypes.shape(colShape)
}

SelectForm.defaultProps = {
    hidden: false,
    placeholder: "",
    style: {
        labelClass: "label-conteudoInferior",
        elementoClass: "select select-form",
        col: {
            xs: 4,
            sm: 4,
            md: 4,
            lg: 4,
            xl: 4
        }
    }
};

SelectForm.propTypes = {
    // label: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    valorInicial: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    maxOptions: PropTypes.number,
    style: PropTypes.shape(styleShape),
}