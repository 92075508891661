import React, { useState, useEffect } from 'react';
import { Col, InputGroup, Row, } from 'reactstrap';
import { Area, AreaChart, Bar, BarChart, Line, LineChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis, PieChart, Pie, Cell, Radar } from 'recharts';
import {TableUsersHome} from '../../../Basicos';

import './GraphCard.css';
import DayPickerInput from 'react-day-picker/DayPickerInput';


import { convertToSlug, jsonToFormData } from '../../funcoes';
import { axiosApi } from '../../../../services/axiosInstances';

const CustomTooltip = ({ active, payload, }) => {
  if (active) {
    return (
      <div className="custom-tooltip">
        {(!payload[1]) ? /* Se não existe um segundo payload não se trata das perguntas respondidas */
          <p className="label">{`${payload[0].value} ${payload[0].name}`}</p>
          :
          (payload[0].value > payload[1].value) ?
            <p className="two-label">
              <span>{`${payload[0].value} Corretas`}</span><br />
              <span>{`${payload[1].value} Erradas`}</span>
            </p>
            :/*(payload[0].value < payload[1].value)*/
            <p className="two-label-inverted">
              <span>{`${payload[1].value} Erradas`}</span><br />
              <span>{`${payload[0].value} Corretas`}</span>
            </p>
        }
      </div>
    );
  }

  return null;
};

const PieGraph = ({
  data,
  dataKey,
  value,
  mainColor = "#90e2e0",
}) => {
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  const style = {
    top: '50%',
    right: 0,
    transform: 'translate(0, -50%)',
    lineHeight: '24px',
  };


  const COLORS = ["#FD7949", "#83FDC4", "#A7C567", "#C374D1", "#430892", "#2502D2"];
  return (
    <ResponsiveContainer
      height={240}
      width="100%">

      <PieChart
        height={400}
        width={400}
      >

        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={80}
          fill="#8884d8"
          dataKey={value} >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Legend
          payload={
            data.map(
              (item, index) => ({
                id: item.datakey,
                type: "square",
                value: `${item.datakey}`,
                color: COLORS[index % COLORS.length]
              })
            )
          } />

      </PieChart>

    </ResponsiveContainer>
  );
};

const AreaGraph = ({
  data,
  dataKey,
  label,
  mainColor = "#90e2e0",
}) => {
  return (
    <ResponsiveContainer
      debounced={300}
      height={240}
      width="100%">

      <AreaChart
        data={data}
        height={200}
        margin={{ left: -20, right: 20, }}
        width={320}>

        <XAxis dataKey="datakey" stroke="#b2b2b2" />
        <YAxis stroke="#b2b2b2" />
        <Tooltip content={<CustomTooltip />} />

        <Area
          dataKey={dataKey}
          fill={mainColor}
          name={label}
          stroke="#fff"
          type="monotone" />
      </AreaChart>
    </ResponsiveContainer>
  );
};
const BarGraph = ({
  data,
  dataKey,
  label,
  mainColor = "#24b3af",
}) => {
  return (
    <ResponsiveContainer
      debounced={300}
      height={240}
      width="100%">

      <BarChart
        data={data}
        height={200}
        margin={{ left: -20, right: 20, }}
        width={320}>

        <XAxis
          dataKey="datakey"
          stroke="#b2b2b2" />
        <YAxis stroke="#b2b2b2" />
        <Tooltip content={<CustomTooltip />} cursor={{ fill: '#fff' }} />

        <Bar
          barSize={40}
          dataKey={dataKey}
          fill={mainColor}
          name={label} />
      </BarChart>
    </ResponsiveContainer>
  );
};
const LineGraph = ({
  data,
  dataKey1,
  dataKey2,
  label1,
  label2,
  mainColor = "#24b3af",
  secondaryColor = "#ffa140",
}) => {
  return (
    <ResponsiveContainer
      debounced={300}
      height={240}
      width="100%">

      <LineChart
        data={data}
        height={300}
        margin={{ left: -20, right: 20, }}
        width={500}>

        <XAxis dataKey="datakey" stroke="#b2b2b2" />
        <YAxis stroke="#b2b2b2" />
        <Tooltip content={<CustomTooltip />} />
        <Legend
          verticalAlign="top"
          iconSize={16}
          iconType="circle"
          wrapperStyle={{ left: 10, }} />

        <Line
          dataKey={dataKey1}
          name={label1}
          stroke={mainColor}
          type="monotone" />
        <Line
          dataKey={dataKey2}
          name={label2}
          stroke={secondaryColor}
          type="monotone" />
      </LineChart>
    </ResponsiveContainer>
  );
}

const GraphCard = ({
  chave, titulo, filtrarView, setIsLoading, graphType }) => {
  const [checked, setChecked] = useState(true);
  const [data, setData] = useState({});
  const [selectedOption, setSelectedOption] = useState('1');


  const loadData = async () => {
    var response;
    let dados = null;
    let url = convertToSlug(titulo);
    let chave = convertToSlug(titulo);
    try {
      switch (url) {
        case 'acessos':
          url = "acessos/";
          chave = "dailyAcess";
          response = await axiosApi.get(`${url}${selectedOption ? selectedOption : '1'}`);
          dados = response.data;
          break;
        case 'acessos-distintos':
          url = "acessos-distintos/";
          chave = "dailyAcessDistinct";
          response = await axiosApi.get(`${url}${selectedOption ? selectedOption : '1'}`);
          dados = response.data;
          break;
        case 'numero-exibicao':
          chave = 'viewsMes';
          response = await axiosApi.post('cliques-e-views-mes', jsonToFormData(filtrarView, 'POST'), {
            headers: { "Content-Type": "multipart/form-data;" }
          }).then(res => {
            dados = res.data.visualizacoes;
          });
          break;
        case 'numero-toque':
          chave = 'clicksMes';
          response = await axiosApi.post('cliques-e-views-mes', jsonToFormData(filtrarView, 'POST'), {
            headers: { "Content-Type": "multipart/form-data;" }
          }).then(response => {
            dados = response.data.cliques;
          });
          break;
        case 'numeros-exibicoes':
          chave = 'views';
          response = await axiosApi.post('cliques-e-views-data', jsonToFormData(filtrarView, 'POST'), {
            headers: { "Content-Type": "multipart/form-data;" }
          }).then(res => {
            dados = res.data.visualizacoes;
          });
          break;
        case 'numeros-toques':
          chave = 'clicks';
          response = await axiosApi.post('cliques-e-views-data', jsonToFormData(filtrarView, 'POST'), {
            headers: { "Content-Type": "multipart/form-data;" }
          }).then(response => {
            dados = response.data.cliques;
          });
          break;
        case 'numeros-exibicoes-por-usuarios':
          chave = 'viewsMesUsuario';
          response = await axiosApi.post('cliques-e-views-pizza', jsonToFormData(filtrarView, 'POST'), {
            headers: { "Content-Type": "multipart/form-data;" }
          }).then(res => {
            dados = res.data.visualizacoes;
          });
          break;
        case 'numeros-toques-por-usuarios':
          chave = 'clicksMesUsuario';
          response = await axiosApi.post('cliques-e-views-pizza', jsonToFormData(filtrarView, 'POST'), {
            headers: { "Content-Type": "multipart/form-data;" }
          }).then(response => {
            dados = response.data.cliques;
          });
          break;
        case 'novos-usuarios':
          url = "novos-usuarios/";
          chave = "newUsers";
          response = await axiosApi.get(`${url}${selectedOption ? selectedOption : '1'}`);
          dados = response.data;
          break;
        case 'perguntas-respondidas':
          url = "quizz/";
          chave = "questionsAnswered";
          response = await axiosApi.get(`${url}${selectedOption ? selectedOption : '1'}`);
          dados = response.data;
          break;
        case 'top-20-usuario':
          url = "top20_usuarios/";
          chave = "tableUser";
          response = await axiosApi.get(`${url}${selectedOption ? selectedOption : '1'}`);
          dados = response.data;
          break;
        default:
          break;
      }
      setData({ ...data, [chave]: dados });
    } catch (e) {
      console.log(e);
    }
  }
  const columns = [
      {
          Header: 'Nome do Usuário',
          accessor: 'nome',
          Cell: props => (
            <div style={{textAlign:"left"}}>
            <img
            src={props.row.original.foto_perfil}
            style={{width:"40px", height:"40px", verticalAlign:"middle", borderRadius:"50%", marginRight:"15px"}}
            alt='Player'
            />
            {props.row.original.nome}</div>
        )
      },
      {
          accessor: "instituicao",
          Header: "Instituição"
      },
      {
          accessor: "categoria",
          Header: "Categoria"
      },
      {
          accessor: "respondidas",
          Header: "Questões Respondidas",
      },
      {
          accessor: "qtd_batalha",
          Header: "Batalhas",
      },
      {
          accessor: "qtd_simulado",
          Header: "Simulados",
      },
      {
          accessor: "qtd_caca_niquel",
          Header: "Caça Níquel",
      },
      {
          accessor: "qtd_personalizado",
          Header: "Personalizado",
      }
  ];

  useEffect(() => {
    loadData();
  }, [selectedOption, filtrarView]);

  const onChangeHandler = (event) => {
    setChecked(!checked);
  }

  const handleOptionChange = (changeEvent) => {
    setSelectedOption(selectedOption ? changeEvent.target.value : '1');
  }
  const chaveId1 = `${convertToSlug(chave)}-first-option`;
  const chaveId2 = `${convertToSlug(chave)}-second-option`;
  const chaveId3 = `${convertToSlug(chave)}-third-option`;
  const chaveId4 = `${convertToSlug(chave)}-quarter-option`;
  const chaveId5 = `${convertToSlug(chave)}-fifth-option`;
  const columnSize = (graphType === "mes-usuario") ? '6' : '12';
  const classSize = (graphType === "mes-usuario") ? 'graph-card graph-card-size' : 'graph-card';


  return (
    <Col className={classSize} sm={columnSize} md={columnSize}>
      <div className="header">
        <h2>{titulo}</h2>
        {(convertToSlug(titulo) !== 'numeros-exibicoes') && (convertToSlug(titulo) !== 'numeros-toques') && (convertToSlug(titulo) !== 'numero-exibicao') && (convertToSlug(titulo) !== 'numero-toque') && (convertToSlug(titulo) !== 'numeros-exibicoes-por-usuarios') && (convertToSlug(titulo) !== 'numeros-toques-por-usuarios') ?
          <div className="buttons-container">
            <input type="radio" id={chaveId1} className="firstOption" value='1' checked={selectedOption === '1'} onChange={handleOptionChange} />
            <label htmlFor={chaveId1}>Dia</label>

            <input type="radio" id={chaveId2} value='7' checked={selectedOption === '7'} onChange={handleOptionChange} />
            <label htmlFor={chaveId2}>7 dias</label>

            <input type="radio" id={chaveId3} className="quiteOption" value='30' checked={selectedOption === '30'} onChange={handleOptionChange} />
            <label htmlFor={chaveId3}>Mês</label>

            <input type="radio" id={chaveId4} value='180' checked={selectedOption === '180'} onChange={handleOptionChange} />
            <label htmlFor={chaveId4}>6 meses</label>

            <input type="radio" id={chaveId5} className="secondOption" value='360' checked={selectedOption === '360'} onChange={handleOptionChange} />
            <label htmlFor={chaveId5}>Ano</label>
          </div>
          : <div></div>}
      </div>

      {(convertToSlug(titulo) === 'acessos') && data.dailyAcess ?
        <AreaGraph
          data={data.dailyAcess}
          dataKey={Object.keys(data.dailyAcess[0])[1]}
          label={titulo} />
        : (convertToSlug(titulo) === 'acessos-distintos') && data.dailyAcessDistinct ?
          <AreaGraph
            data={data.dailyAcessDistinct}
            dataKey={Object.keys(data.dailyAcessDistinct[0])[1]}
            label={titulo} />
          : (convertToSlug(titulo) === 'numeros-exibicoes') && data.views ?
            <AreaGraph
              data={data.views}
              dataKey={Object.keys(data.views[0])[1]}
              label={titulo} />
            : (convertToSlug(titulo) === 'numeros-toques') && data.clicks ?
              <AreaGraph
                data={data.clicks}
                dataKey={Object.keys(data.clicks[0])[1]}
                label={titulo} />
              : (convertToSlug(titulo) === 'numero-exibicao') && data.viewsMes ?
                <AreaGraph
                  data={data.viewsMes}
                  dataKey={Object.keys(data.viewsMes[0])[1]}
                  label={titulo} />
                : (convertToSlug(titulo) === 'numero-toque') && data.clicksMes ?
                  <AreaGraph
                    data={data.clicksMes}
                    dataKey={Object.keys(data.clicksMes[0])[1]}
                    label={titulo} />
                  : (convertToSlug(titulo) === 'numeros-exibicoes-por-usuarios') && data.viewsMesUsuario ?
                    <PieGraph
                      data={data.viewsMesUsuario}
                      dataKey={Object.keys(data.viewsMesUsuario[0])[1]}
                      value={'exibicoes'}
                      label={titulo} />
                    : (convertToSlug(titulo) === 'numeros-toques-por-usuarios') && data.clicksMesUsuario ?
                      <PieGraph
                        data={data.clicksMesUsuario}
                        dataKey={Object.keys(data.clicksMesUsuario[0])[1]}
                        value={'cliques'}
                        label={titulo} />
                      : (convertToSlug(titulo) === 'novos-usuarios') && data.newUsers ?
                        <BarGraph
                          data={data.newUsers}
                          dataKey={Object.keys(data.newUsers[0])[1]}
                          label={titulo} />
                        : (convertToSlug(titulo) === 'perguntas-respondidas') && data.questionsAnswered ?
                          <LineGraph
                            data={data.questionsAnswered}
                            dataKey1={Object.keys(data.questionsAnswered[0])[1]}
                            dataKey2={Object.keys(data.questionsAnswered[0])[2]}
                            label1={"Corretas"}
                            label2={"Erradas"} />
                          : (convertToSlug(titulo) === 'top-20-usuario') && data.tableUser ?
                            <TableUsersHome 
                              colunas={columns}
                              dados={data.tableUser} />
                            : data.questionsAnswered && <LineGraph
                              data={data.questionsAnswered}
                              dataKey1={Object.keys(data.questionsAnswered[0])[1]}
                              dataKey2={Object.keys(data.questionsAnswered[0])[3]}
                              label1={"Corretas"}
                              label2={"Erradas"} />}
    </Col>

  );
}
export default GraphCard;
