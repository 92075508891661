import React, { useMemo, useEffect, useState, Fragment } from 'react';
import { useDropzone } from 'react-dropzone';
import { IoMdImages } from 'react-icons/io';


const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 'auto',
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '20px',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: 'red'
};

function ImageDropzone(props) {
    const [files, setFiles] = useState([]);
    const maxSize = 1000000;

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
        acceptedFiles,
    } = useDropzone({
        accept: 'image/png',
        maxSize,
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        },
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    const thumbs = files.map(file => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img
                    src={file.preview}
                    style={img}
                    alt=''
                />
            </div>
        </div>
    ));
   const handleErrorInputFile = () => {
       let classes = 'container';
       if(isDragReject){
         return `${classes} border-danger bg-light`;
       }
   }
        
    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    const filepath = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
            {props.setImg(file)}
        </li>
    ));

    return (
        <div className="container" onChange={handleErrorInputFile()}>
            <div {...getRootProps({ style })}>
                {!props.disabled&&<Fragment><input {...getInputProps()} /><h3>Adicione um Icone</h3></Fragment>}
                

                {props.imagemBanco && !thumbs.length
                    && Object.getOwnPropertyNames(props.imagemBanco).length ?
                    <img src={props.imagemBanco} height='90' width='auto' /> :
                    thumbs.length === 0 ?
                        <IoMdImages size='5rem' /> :
                        <div style={thumbsContainer}>
                            {thumbs}
                        </div>
                }                  
                {!props.disabled&&<p>Arraste e solte a imagem aqui ou clique para selecioná-la.</p>}
                
            </div>
           
        </div>
    );
}

export default ImageDropzone;