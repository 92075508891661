import React from 'react';
import { Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { IoIosSearch } from 'react-icons/io';

const _SearchBar = ({
    children,
    className,
    inputValue,
    onChangeTexto
}) => {
    return (
        <div className={className}>
            <InputGroup>
                <InputGroupAddon addonType="prepend">
                    <IoIosSearch color="#999" size="2rem" />
                </InputGroupAddon>

                <Input
                    placeholder="Pesquisar"
                    type="text"
                    value={inputValue}
                    onChange={onChangeTexto}
                />

                {/* <InputGroupAddon addonType="append"></InputGroupAddon> */}
            </InputGroup>
            {children}
        </div>
    )
}

export { _SearchBar as SearchBar };
